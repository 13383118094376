import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Translate } from "react-localize-redux";
import {connect} from "react-redux";
import {getUser} from "../../state/root";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

function Page404({targetUrl}) {
  return (
    <Wrapper>
      <Typography component="h1" variant="h1" align="center" gutterBottom>
          <Translate id="digitalRetail.common.page_404.404" />
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
          <Translate id="digitalRetail.common.page_404.page_not_found" />
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
          <Translate id="digitalRetail.common.page_404.page_have_removed" />
      </Typography>

      <Button
        component={Link}
        to={targetUrl}
        variant="contained"
        color="secondary"
      >
          <Translate id="digitalRetail.common.page_404.return_to_website" />
      </Button>
    </Wrapper>
  );
}

const mapStateToProps = state => ({
   targetUrl: getUser(state) ? '/' : '/signin',
});

export default connect(mapStateToProps)(Page404);
