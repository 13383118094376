import React from "react";
import { connect } from "react-redux";
import {Translate, withLocalize} from "react-localize-redux";
import { matchPath } from 'react-router-dom';
import { renderToStaticMarkup } from "react-dom/server";
import objectPath from 'object-path';
import {checkLogin} from "../api";
import {publicRoutes, publicLayoutWithHeader} from "../config";
import localization from "../localization";
import {getDealerForTheme, getLocale, getLocales, getSelectedDealer, isGranted} from "../state/root";
import {getUser} from "../state/root";
import {selectDealer, setRefreshToken, setToken, setGoogleSocialObject} from "../state/authentication";
import View from '../components/App';
import {addSnack, setLocale, setShouldHideHeader} from "../state/modix";
import qs from "qs";
import {locales} from "../state/locales";
import GoogleTagManager from 'react-gtm-module'
import GoogleAnalytics from 'react-ga';
import {editProfile} from "../state/profile";

class App extends React.Component
{
    isPublic: false;
    forcedLocale: false;

    constructor(props) {
        super(props);
        const {dispatch, setShouldHideHeader, locales, locale, persistLocale} = props;

        const token = localStorage.getItem('token') || null;
        if (null !== token)
            dispatch(setToken(token));

        const refreshToken = localStorage.getItem('refresh_token') || null;
        if (null !== refreshToken)
            dispatch(setRefreshToken(refreshToken));

        const googleSocialObject = localStorage.getItem('google_social_object');
        if (null !== googleSocialObject)
            dispatch(setGoogleSocialObject(JSON.parse(googleSocialObject)));

        // check's if the initially called url is a public route
        // and if so, set the complete app in public/unauthenticated mode
        publicRoutes.forEach(route => {
            if (null !== matchPath(window.location.pathname, route))
                this.isPublic = true;
        });

        // check's if the initially called url is a public with header route
        // and if so, set the complete app in public/unauthenticated mode
        publicLayoutWithHeader.forEach(route => {
            if (null !== matchPath(window.location.pathname, route)) {
                this.isPublic = true;
                if (route.hideHeaderBeforeThemeIsAvailable) {
                    setShouldHideHeader(true);
                }
            }
        });

        this.initLocalization().then(() => {
            if (!this.isPublic) {
                checkLogin().then(user => {
                    const storedDealer = JSON.parse(localStorage.getItem('selected_dealer'));

                    if (storedDealer && user?.employments.length) {
                        dispatch(selectDealer(storedDealer, user.employments));
                    } else if (!props.isGranted('ROLE_ADMIN') && user?.employments.length) {
                        dispatch(selectDealer(user?.employments[0].dealer));
                    } else {
                        dispatch(selectDealer(null));
                    }

                    if (user.locale) {
                        if (!this.forcedLocale && 'undefined' !== locales[user.locale]) {
                            const {setActiveLanguage, setLocale} = this.props;
                            setLocale(user.locale);
                            setActiveLanguage(user.locale);
                        }
                    } else if (locale) {
                        persistLocale(user, locale);
                    }

                    dispatch(addSnack({
                        variant: "success",
                        message: <Translate id="logged_in" data={{email : user?.email}}/>,
                        duration: 3000
                    }));
                }).then(() => {
                    this.initAnalyticsModules();
                }).catch(reason => {
                    console.log(reason); // this console log is intended
                    dispatch(addSnack({variant: "error", message: <Translate id="login_issue" />}));
                    window.location.href = `${process.env.REACT_APP_LOGIN_URL}?redirectURL=${window.location.href}`;
                });
            }
        });
    }

    initLocalization() {
        const { initialize, addTranslationForLanguage, setActiveLanguage, locale, setLocale, handleFetchLocales} = this.props;
        return new Promise(resolve => {
            handleFetchLocales().then(resolve);
            initialize({
                options: {
                    renderToStaticMarkup,
                    onMissingTranslation: translation => {
                        return  objectPath.get(localization.translations[localization.fallbackLocale], translation.translationId) // We are searching if Translate key exists in fallback language
                            ?
                            translation.defaultTranslation // If translation key exists, we return the fallback translation which is in defaultTranslation
                            :
                            "{Missing translationId:  "+ translation.translationId + " ("+ translation.languageCode +")" + "}"; // /If translation key does not exist, we return a custom message to know which key is missing.
                    },
                },
                languages: Object.keys(localization.locales).map(code => ({code, name: localization.locales[code]})),
            });

            Object.keys(localization.translations).forEach(locale => {
                addTranslationForLanguage(localization.translations[locale], locale);
            });

            let localeToSet = locale;
            const queryStringParams = qs.parse(window.location.search, {ignoreQueryPrefix: true});
            if (queryStringParams.locale) {
                localeToSet = queryStringParams.locale;
                this.forcedLocale = true;
            }

            setLocale(localeToSet);
            setActiveLanguage(localeToSet);

            if (this.forcedLocale)
                resolve();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {user, selectedDealer, setActiveLanguage, locale} = this.props;
        if ((prevProps.user === null && user !== null) || (user && user?.id !== prevProps?.user?.id)) {
            if (user?.employments.length === 1 && selectedDealer?.id !== user?.employments[0].dealer.id) {
                this.props.dispatch(selectDealer(user?.employments[0].dealer));
            }
        }

        if (prevProps.locale !== this.props.locale)
            setActiveLanguage(locale);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {user, selectedDealer, dealerForTheme, locale} = this.props;
        if (locale !== nextProps.locale)
            return true;

        if (!user && nextProps.user || user && !nextProps.user || user && nextProps.user && user.id !== nextProps.user.id)
            return true;

        if (!dealerForTheme && nextProps.dealerForTheme || dealerForTheme && !nextProps.dealerForTheme ||
            dealerForTheme && nextProps.dealerForTheme && dealerForTheme.id !== nextProps.dealerForTheme.id)
            return true;

        if (!selectedDealer && nextProps.selectedDealer || selectedDealer && !nextProps.selectedDealer ||
            selectedDealer && nextProps.selectedDealer && selectedDealer.id !== nextProps.selectedDealer.id)
            return true;

        return false;
    }

    initAnalyticsModules(){
        const { selectedDealer, dealerForTheme, user } = this.props;
        const dealerModConfig = selectedDealer?.moduleConfig || dealerForTheme?.moduleConfig || user?.consumer?.moduleConfig;

        if (!dealerModConfig) { return }

        if (dealerModConfig?.google_analytics?.enabled) {
            const gaId = dealerModConfig.google_analytics?.params?.publishable_key || process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

            if (gaId?.trim()?.length > 0) {
                GoogleAnalytics.initialize(gaId);
            }
        }

        if (dealerModConfig?.google_tag_manager?.enabled) {
            const gtmId = dealerModConfig.google_tag_manager?.params?.publishable_key || process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

            if (gtmId?.trim()?.length > 0) {
                GoogleTagManager.initialize({
                    gtmId
                });
            }
        }
    }

    render() {
        return <View isPublic={this.isPublic} {...this.props}/>;
    }
}

const mapStateToProps = state => ({
    user: getUser(state),
    isGranted: attribute => isGranted(state, attribute),
    locale: getLocale(state),
    locales: getLocales(state),
    selectedDealer: getSelectedDealer(state),
    dealerForTheme: getDealerForTheme(state)
});

const mapDispatchToProps = dispatch => ({
    dispatch,
    setLocale: locale => dispatch(setLocale(locale)),
    handleFetchLocales: () => dispatch(locales.action()),
    persistLocale: (user, locale) => dispatch(editProfile.action({data: {id: user.id, locale}})),
    setShouldHideHeader: hidden => dispatch(setShouldHideHeader(hidden)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(App));
