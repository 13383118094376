import React, {useEffect, useState} from "react";
import {Paper, Typography, Grid, LinearProgress} from "@material-ui/core";
import AuthLayout from "../Layout/Public";
import {withStyles} from "@material-ui/core";
import Logo from "./Logo";
import {Translate} from "react-localize-redux";

const styles = theme => ({
    paper: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    wrapper: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flexBasis: '100%',
    },
    progress: {
        width: '100%',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
    title: {
        width: '100%',
        marginTop: theme.spacing(8),
    },
    text: {
        width: '100%',
        textAlign: 'center',
    },
});

const Loading = ({
    classes, title, firstLine, secondLine, theme
}) => {
    return(
        <AuthLayout>
            <Grid
                container
                direction="column"
            >
                <Grid item>
                    <Paper elevation={16} className={classes.paper}>
                        <div className={classes.wrapper}>
                            <div>
                                <Logo
                                    logoType="lightBackground"
                                    theme={theme}
                                />
                            </div>
                            <div className={classes.progress}>
                                <LinearProgress color="primary" />
                            </div>
                            <div className={classes.text}>
                                <Typography component="h2" variant="h5" gutterBottom className={classes.text}>
                                    {typeof firstLine !== "undefined"
                                        ?
                                        firstLine
                                        :
                                        <Translate id="auth_service.loading.just_one_moment" />
                                    }
                                </Typography>
                                <Typography component="h2" variant="body1" gutterBottom>
                                    {typeof secondLine !== "undefined"
                                        ?
                                        secondLine
                                        :
                                        <Translate id="auth_service.loading.credentials_check" />
                                    }
                                </Typography>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </AuthLayout>
    );
}

export default withStyles(styles, {withTheme: true})(Loading);
