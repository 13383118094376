import {schema} from 'normalizr'
import Dealer from "./entities/Dealer";
import Product from "./entities/Product";
import Order from "./entities/Order";
import denormalize from './denormalize';


export const appItem = new schema.Entity('apps');
export const appList = new schema.Array(appItem);

export const userItem = new schema.Entity('users');
export const userList = new schema.Array(userItem);

export const menuItem = new schema.Entity('menuItems');
export const menuItemList = new schema.Array(menuItem);

export const inquiryItem = new schema.Entity('inquiries');
export const inquiryList = new schema.Array(inquiryItem);

export const inquiryMessageItem = new schema.Entity('inquiryMessages');
export const inquiryMessagesList = new schema.Array(inquiryMessageItem);

export const blockedTestDriveTimeSlotsItem = new schema.Entity('blockedTestDriveTimeSlots');
export const blockedTestDriveTimeSlotsList = new schema.Array(blockedTestDriveTimeSlotsItem);

export const dealerInvitesItem = new schema.Entity('invites');
export const dealerInvitesList = new schema.Array(dealerInvitesItem);

export const userAccountItem = new schema.Entity('user_accounts');
export const userAccountList = new schema.Array(userAccountItem);

export const order = new schema.Entity('orders');
export const orderList = new schema.Array(order);

export const paymentItem = new schema.Entity('payments',  {}, { idAttribute: '@id' });
export const paymentList = new schema.Array(paymentItem);

export const orderItem = new schema.Entity('orderItems');
export const orderItemList = new schema.Array(orderItem);

export const orderMessageItem = new schema.Entity('orderMessages');
export const orderMessagesList = new schema.Array(orderMessageItem);

export const dealerItem = new schema.Entity(Dealer.schemaKey);
export const dealerList = new schema.Array(dealerItem);

export const employmentItem = new schema.Entity('employments');
export const employmentList = new schema.Array(employmentItem);

export const countryItem = new schema.Entity('counties');
export const countryItemList = new schema.Array(countryItem);

export const localeItem = new schema.Entity('locales');
export const localeItemList = new schema.Array(localeItem);

export const dealerDocumentItem = new schema.Entity('dealerDocuments');
export const dealerDocumentList = new schema.Array(dealerDocumentItem);

export const mediaObjectItem = new schema.Entity('media_objects');

export const consumerItem = new schema.Entity('consumers');

export const productItem = new schema.Entity('products');
export const productList = new schema.Array(productItem);

export const docusignEnvelopeItem = new schema.Entity('docusignEnvelopes');

export const partExchangeItem = new schema.Entity('partExchanges');

docusignEnvelopeItem.define({
    order: order
});

userItem.define({
    employments: employmentList,
    userAccounts: userAccountList,
    avatar: mediaObjectItem,
    consumer: consumerItem
});

consumerItem.define({
    user: userItem,
});

dealerItem.denormalize = (entity, unvisit) => denormalize(Dealer, dealerItem.schema, entity, unvisit);
dealerItem.define({
    employments: employmentList,
    dealerDocuments :dealerDocumentList
});

productItem.denormalize = (entity, unvisit) => denormalize(Product, productItem.schema, entity, unvisit);
productItem.define({
    dealer: dealerItem
});

employmentItem.define({
    dealer: dealerItem,
    user: userItem
});

dealerDocumentItem.define({
    dealer: dealerItem
});

menuItem.define({
    children: menuItemList,
});

inquiryItem.define({
    user: userItem,
    assignedEmployee: employmentItem,
    messages: inquiryMessagesList,
});

inquiryMessageItem.define({
    inquiry: inquiryItem
});

order.denormalize = (entity, unvisit) => denormalize(Order, order.schema, entity, unvisit);
order.define({
    messages: orderMessagesList,
    consumer: consumerItem,
    dealer: dealerItem,
    docusignEnvelope: docusignEnvelopeItem,
    orderItems: orderItemList,
    payments: paymentList,
    partExchange: partExchangeItem,
});

paymentItem.define({
    order: order
});

orderItem.define({
    product: productItem,
});

orderMessageItem.define({
    order: order
});

partExchangeItem.define({
    order: order
});
