import createRemoteAction from '../createRemoteAction'
import {createAction, handleActions} from "redux-actions";

export const setGoogleLoginFetching = createAction('SIGNIN_GOOGLE_FETCHING');

export const signin = createRemoteAction({
    type: "SIGNIN",
    method: "POST",
    url: "/api/login",
    headers: {
        'X-NoRefreshToken': 1
    }
});

const initialState = {
    isLoginFetching: false,
    isGoogleLoginFetching: false,
};

export default handleActions({
    [signin.begin]: state => ({
        ...state,
        isLoginFetching: true
    }),
    [signin.request]: state => ({
        ...state,
        isLoginFetching: false
    }),
    [setGoogleLoginFetching]: (state, action) => ({
        ...state,
        isGoogleLoginFetching: action.payload
    }),
}, initialState);

export const isLoginFetching = state => state.isLoginFetching;
export const isGoogleLoginFetching = state => state.isGoogleLoginFetching;