import React from "react";
import View from '../../components/Layout/Welcome';
import {connect} from "react-redux";
import {getUser} from "../../state/root";

class Welcome extends React.Component
{
    render() {
        return <View {...this.props} />;
    }
}

const mapStateToProps = state => ({
    user: getUser(state),
});

export default connect(mapStateToProps)(Welcome);