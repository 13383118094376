import {createAction, handleActions} from 'redux-actions';
import localization from '../localization';
import {denormalize} from "normalizr";
import {appItem} from "../schema";
import React from "react";

export const setRedirect = createAction('SET_REDIRECT');

const setLocaleInternal = createAction('MODIX_SET_LOCALE');
export const setLocale = locale => dispatch => {
    // check available locales before set it.
    if (!Object.prototype.hasOwnProperty.call(localization.locales, locale)) { return }
    localStorage.setItem('languageSelected', locale);
    dispatch(setLocaleInternal(locale));
};
export const addSnack = createAction('SNACKBAR_ADD_SNACK');
export const removeSnack = createAction('SNACKBAR_REMOVE_SNACK');
export const setDealerForTheme = createAction('SET_DEALER_FOR_THEME');
export const setShouldHideHeader = createAction('SET_HIDE_HEADER');

const initialState = {
    locale: localStorage.getItem('languageSelected') || localization.fallbackLocale,
    snacks: {},
    redirect: null,
    dealerForTheme: null,
    shouldHideHeader: false,
};

export default handleActions({
    [setRedirect]: (state, action) => ({
        ...state,
        redirect: action.payload
    }),
    [setDealerForTheme]: (state, action) => ({
        ...state,
        dealerForTheme: action.payload
    }),
    [setShouldHideHeader]: (state, action) => ({
        ...state,
        shouldHideHeader: action.payload
    }),
    [setLocaleInternal]: (state, action) => ({
        ...state,
        locale: action.payload,
    }),
    [addSnack]: (state, action) => {
        const key = Math.random().toString(36);
        return {
            ...state,
            snacks: {...state.snacks, [key]: {...action.payload, key}},
        };
    },
    [removeSnack]: (state, action) => {
        const snacks = {...state.snacks};
        delete snacks[action.payload.key];
        return {
            ...state,
            snacks,
        };
    },
}, initialState);

export const getLocale = state => state.locale;
export const getApp = (state, entities) => denormalize(state.appId, appItem, entities);
export const getSnack = state => state.snacks[Object.keys(state.snacks)[0]];
export const getRedirect = state => state.redirect;
export const getDealerForTheme = state => state.dealerForTheme;
export const getShouldHideHeader = state => state.shouldHideHeader;
