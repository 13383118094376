import createRemoteAction from "../createRemoteAction";
import {
    dealerItem,
    dealerList
} from "../schema";
import {handleActions} from "redux-actions";
import {denormalize, normalize} from "normalizr";
import bqs from '../buildQueryString';
import Dealer from "../entities/Dealer";

export const dealers = createRemoteAction({
    type: 'DEALERS',
    url: props =>  '/api/dealers?' + bqs(props),
    schema: dealerList,
});

export const dealerSettings = createRemoteAction({
    type: 'DEALER',
    url: ({dealer}) =>  '/api/dealers/'+ dealer.id,
    schema: dealerItem,
});

export const authEditDealerOpeningHours = createRemoteAction({
    type: 'EDIT_DEALER_OPENING_HOURS',
    method: 'PUT',
    url: dealer => '/api/dealers/'+ dealer.data.id + '/opening-hours',
    schema: dealerItem,
});

export const getDealerOpeningHours = createRemoteAction({
    type: 'GET_DEALER_OPENING_HOURS',
    method: 'GET',
    url: dealer => '/api/dealers/'+ dealer.id + '/opening-hours',
    schema: dealerItem,
});

export const createDealer = createRemoteAction({
    type: 'CREATE_DEALER',
    method: 'POST',
    url: '/api/dealers'
});

export const detachUserDealer = createRemoteAction({
    type: 'DETACH_USER_DEALER',
    method: 'DELETE',
    url: props => '/api/employments/' + props.data.employment.id
});

export const attachUserDealer = createRemoteAction({
    type: 'ATTACH_USER_DEALER',
    method: 'POST',
    url: '/api/employments',
    schema: dealerItem,
});

export const attachUserDealerPublic = createRemoteAction({
    type: 'ATTACH_USER_DEALER_PUBLIC',
    method: 'POST',
    url:  '/api/employments',
    schema: dealerItem,
    headers: {
        'Authorization': undefined
    }
});

export const editDealer = createRemoteAction({
    type: 'EDIT_DEALER',
    method: 'PUT',
    url: props => '/api/dealers/'+ props.context.id,
    schema: dealerItem,
});

export const deleteDealer = createRemoteAction({
    type: 'DELETE_DEALER',
    method: 'DELETE',
    url:  props => '/api/dealers/'+ props.data.id,
});

export const themeColorConfig = createRemoteAction({
    type: 'THEME_COLOR_CONFIG',
    method: 'PUT',
    url: props => '/api/dealers/'+ props.context.id+'/theme-color',
    schema: dealerItem,
});

export const themeColorReset = createRemoteAction({
    type: 'THEME_COLOR_RESET',
    method: 'PUT',
    url: props => '/api/dealers/'+ props.data.id+'/theme-color',
    schema: dealerItem,
});

export const uploadLogo = createRemoteAction({
    type: 'UPLOAD_LOGO',
    method: 'POST',
    headers: {
        "Content-Type": 'multipart/form-data'
    },
    url: '/api/media-objects/logo'
});

export const uploadDealerDocuments = createRemoteAction({
    type: "UPLOAD_DEALER_DOCUMENTS",
    method: "POST",
    headers: {
        "Content-Type": 'multipart/form-data'
    },
    url: `/api/dealer-documents`,
    extraStateManagement: (response) => {
        if (response?.data?.dealer?.id) {
            let dealerId = response.data.dealer.id;
            let dealer = {
                id: dealerId,
                dealerDocuments: [response.data]
            };
            return normalize(dealer, dealerItem);
        }
    },
    forceArrayPush: true,
    forceArrayPushForField: 'dealerDocuments'
});

export const deleteDealerDocument = createRemoteAction({
    type: "DELETE_DEALER_DOCUMENTS",
    method: "DELETE",
    url: props => `/api/dealer-documents/${props.data.id}`,
    extraStateManagement: (response) => {
        if (response?.data?.dealer.dealerDocuments && response?.data?.id) {
            let dealerDocumentId = response.data.id;
            let dealer = response.data.dealer;
            dealer.dealerDocuments = dealer.dealerDocuments.filter(dealerDocument => dealerDocument.id !== dealerDocumentId);
            return normalize(dealer, dealerItem);
        }
    },
});

export const downloadDealerDocument = createRemoteAction({
    type: "DOWNLOAD_DEALER_DOCUMENTS",
    method: "GET",
    url: props => `/api/media-objects/${props.id}/serve`,
    responseType:'blob',
});

export const updateDealerReservationFeeInCents = createRemoteAction({
    type: 'UPDATE_DEALER_RESERVATION_FEE_IN_CENTS',
    method: 'PUT',
    url: dealer => '/api/dealers/'+ dealer.data.id + '/reservation-fee',
    schema: dealerItem,
});

export const updateDealerTermsAndConditions = createRemoteAction({
    type: 'UPDATE_DEALER_TERMS_AND_CONDITIONS',
    method: 'PUT',
    url: props => `/api/dealers/${props.context.id}/terms-and-conditions`,
    schema: dealerItem,
    replaceProperties: [
        {
            schemaKey: Dealer.schemaKey,
            name: 'termsAndConditions',
        }
    ]
});

export const updateDealerAnalytics = createRemoteAction({
    type: 'UPDATE_DEALER_ANALYTICS',
    method: 'PUT',
    url: props => `/api/dealers/${props.context.id}/analytics`,
    schema: dealerItem,
});

export const modulesConfig = createRemoteAction({
    type: 'GET_MODULES_CONFIG',
    method: 'GET',
    url: '/api/configs/modules',
    headers: {
        'Authorization': undefined
    }
});

const initialState = {
    ids: [],
    fetching: false,
    fetchingCreate: false,
    fetchingUploadDocuments:false,
    fetchingEdit: false,
    fetchingDelete: false,
    fetchingDetachUser: false,
    fetchingAttachUser: false,
    fetchingAttachUserPublic: false,
    fetchingModulesConfig: false,
    logoUploading: false,
    themeConfigUpdating: false,
    downloadingDealerDocument: false,
    resetThemeConfigUpdating: false,
    error: null,
    count: 0,
};

export default handleActions({
    [dealers.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [dealers.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
        count: action.error ? 0 : action.payload.count,
    }),
    [createDealer.begin]: state => ({
        ...state,
        fetchingCreate: true,
    }),
    [createDealer.request]: state => ({
        ...state,
        fetchingCreate: false,
    }),
    [editDealer.begin]: state => ({
        ...state,
        fetchingEdit: true,
    }),
    [editDealer.request]: state => ({
        ...state,
        fetchingEdit: false,
    }),
    [deleteDealer.begin]: state => ({
        ...state,
        fetchingDelete: true,
    }),
    [deleteDealer.request]: deleteDealer.deleteReducer('fetchingDelete'),
    [detachUserDealer.begin]: state => ({
        ...state,
        fetchingDetachUser: true,

    }),
    [detachUserDealer.request]: state => ({
        ...state,
        fetchingDetachUser: false,
    }),
    [attachUserDealer.begin]: state => ({
        ...state,
        fetchingAttachUser: true,

    }),
    [attachUserDealer.request]: state => ({
        ...state,
        fetchingAttachUser: false,
    }),
    [attachUserDealerPublic.begin]: state => ({
        ...state,
        fetchingAttachUserPublic: true,

    }),
    [attachUserDealerPublic.request]: state => ({
        ...state,
        fetchingAttachUserPublic: false,
    }),
    [dealerSettings.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [dealerSettings.request]: (state, action) => ({
        ...state,
        error: action.error,
        id: action.error ? null : action.payload.normalized.result,
        fetching: false,
    }),
    [authEditDealerOpeningHours.begin]: state => ({
        ...state,
        fetchingEdit: true,
    }),
    [authEditDealerOpeningHours.request]: state => ({
        ...state,
        fetchingEdit: false,
    }),
    [getDealerOpeningHours.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [getDealerOpeningHours.request]: state => ({
        ...state,
        fetching: false,
    }),
    [uploadLogo.begin]: state => ({
        ...state,
        logoUploading: true,
    }),
    [uploadLogo.request]: state => ({
        ...state,
        logoUploading: false,
    }),
    [themeColorConfig.begin]: state => ({
        ...state,
        themeConfigUpdating: true,
    }),
    [themeColorConfig.request]: state => ({
        ...state,
        themeConfigUpdating: false,
    }),
    [themeColorReset.begin]: state => ({
        ...state,
        resetThemeConfigUpdating: true,
    }),
    [themeColorReset.request]: state => ({
        ...state,
        resetThemeConfigUpdating: false,
    }),
    [uploadDealerDocuments.begin]: state => ({
        ...state,
        fetchingUploadDocuments: true,
    }),
    [uploadDealerDocuments.request]: state => ({
        ...state,
        fetchingUploadDocuments: false,
    }),
    [downloadDealerDocument.begin]: state => ({
        ...state,
        downloadingDealerDocument: true,
    }),
    [downloadDealerDocument.request]: state => ({
        ...state,
        downloadingDealerDocument: false,
    }),
    [updateDealerReservationFeeInCents.begin]: state => ({
        ...state,
        fetchingEdit: true,
    }),
    [updateDealerReservationFeeInCents.request]: state => ({
        ...state,
        fetchingEdit: false,
    }),
    [updateDealerTermsAndConditions.begin]: state => ({
        ...state,
        fetchingEdit: true,
    }),
    [updateDealerTermsAndConditions.request]: state => ({
        ...state,
        fetchingEdit: false,
    }),
    [modulesConfig.begin]: state => ({
        ...state,
        fetchingModulesConfig: true,
    }),
    [modulesConfig.request]: state => ({
        ...state,
        fetchingModulesConfig: false,
    }),
    [updateDealerAnalytics.begin]: state => ({
        ...state,
        fetchingEdit: true,
    }),
    [updateDealerAnalytics.request]: state => ({
        ...state,
        fetchingEdit: false,
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getDealers = (state, entities) => denormalize(state.ids, dealerList, entities);
export const isDealerFetching = state => state.fetching;
export const isDealerCreateFetching = state => state.fetchingCreate;
export const isEditDealerFetching = state => state.fetchingEdit;
export const isDealerDeleteFetching = state => state.fetchingDelete;
export const isUserDealerDetachFetching = state => state.fetchingDetachUser;
export const isUserDealerAttachFetching = state => state.fetchingAttachUser;
export const isUserDealerAttachFetchingPublic = state => state.fetchingAttachUserPublic;
export const isFetchingDealer = state => state.fetching;
export const isFetchingDeleteDealerInvite = state => state.fetchingDeleteDealerInvite;
export const isModulesConfigFetching      = state => state.fetchingModulesConfig;
export const getTotalDealers              = state => state.count;
export const isLogoUploading              = state => state.logoUploading;
export const isThemeConfigUpdating        = state => state.themeConfigUpdating;
export const isFetchingUploadDocuments = state => state.fetchingUploadDocuments;
export const isResetThemeConfigUpdating = state => state.resetThemeConfigUpdating;
export const isDownloadingDealerDocument = state => state.downloadingDealerDocument;
export const getDealerById = (state, entities, id) => {
    const dealer = denormalize(id, dealerItem, entities);
    if(dealer)
        return dealer;
};
export const getDealerOpeningHoursById = (state, entities, id) => {
    const dealer = denormalize(id, dealerItem, entities);
    if (dealer?.openingHours)
        return dealer.openingHours;
    return null;
};
