class AbstractEntity
{
    fromObject = object => {
        Object.keys(object).forEach(key => {
            if (object.hasOwnProperty(key)) {
                this[key] = object[key];
            }
        });
    }
}

export default AbstractEntity;