import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ProtectedLayout from "../components/Layout/Protected";
import ProtectedWithoutSidebar from "../components/Layout/ProtectedWithoutSidebar";
import PublicLayout from "../components/Layout/Public";
import PublicLayoutWithHeader from "../components/Layout/PublicLayoutWithHeader";
import Page404 from "../components/General/Page404";
import {getMenuItems, getMobileOpen, isMenuItemsFetching} from "../state/root";
import {getUser} from "../state/root";
import Welcome from "./Layout/Welcome";
import Loader from "../components/General/Loader";
import {componentMap, publicRoutes, protectedLayoutWithoutSidebar, publicLayoutWithHeader} from "../config";
import {getRedirect} from "../state/root";
import {setRedirect} from "../state/modix";
import history from '../history';
import {setMobileOpen} from "../state/menu";

export const getComponent = type => {
    let Component = componentMap[type];
    if (!Component)
        Component = Page404;

    return Component;
};

const childRoutes = (Layout, routes, layoutProps) =>
    routes.map(({children, path, component}, index) => {

        if (children && children.length > 0) {
            return children.map(({path, component, ...rest}, index) => {
                let a = typeof Layout;
                if (rest.children && rest.children.length > 0) {
                    childRoutes(Layout, rest.children, layoutProps);
                }
                const Component = getComponent(component);
                return (
                    <Route
                        key={index}
                        path={path}
                        exact
                        render={props => (
                            <Layout {...layoutProps}>
                                <Component {...props} />
                            </Layout>
                        )}
                    />
                );
            });
        }

        const Component = getComponent(component);
        return (
            <Route
              key={index}
              path={path}
              exact
              render={props => (
                  <Layout {...layoutProps}>
                      <Component {...props} />
                  </Layout>
              )}
            />
        );
    }
);

const getRedirectComponent = (redirect, path) => {
    const component = <Redirect to={path} push />;
    redirect(null);
    return component;
};

class Routes extends React.Component
{
    shouldComponentUpdate(nextProps, nextState, nextContext) {

        const {
            user,
            menuItems,
            menuItemsFetching,
            redirectPath,
            mobileOpen
        } = this.props;

        if (mobileOpen !== nextProps.mobileOpen)
            return true;

        if (!user && nextProps.user || user && !nextProps.user || user && nextProps.user && user.id !== nextProps.user.id)
            return true;

        if (menuItemsFetching !== nextProps.menuItemsFetching)
            return true;

        if (menuItems.length !== nextProps.menuItems.length)
            return true;

        if (redirectPath !== nextProps.redirectPath)
            return true;

        return false;
    }

    render() {
        const {
            user,
            menuItems,
            menuItemsFetching,
            redirectPath,
            redirect,
            mobileOpen,
            setMobileOpen,
        } = this.props;
        const layoutProps = {mobileOpen, setMobileOpen};
        return (
            <Router history={history}>
                {
                    <Switch>
                        {redirectPath && getRedirectComponent(redirect, redirectPath)}
                        {user && <Route
                            key="welcome"
                            path="/"
                            exact
                            render={() => (
                                <ProtectedLayout {...layoutProps}>
                                    <Welcome/>
                                </ProtectedLayout>
                            )}
                        />}

                        {user && childRoutes(ProtectedLayout, menuItems, layoutProps)}
                        {user && childRoutes(ProtectedWithoutSidebar, protectedLayoutWithoutSidebar, layoutProps)}
                        {childRoutes(PublicLayout, publicRoutes, layoutProps)}
                        {childRoutes(PublicLayoutWithHeader, publicLayoutWithHeader, layoutProps)}

                        {menuItemsFetching ? <ProtectedLayout {...layoutProps}>
                            <Loader/>
                        </ProtectedLayout> : <Route
                            render={() => (
                                <ProtectedLayout {...layoutProps}>
                                    <Page404/>
                                </ProtectedLayout>
                            )}
                        />}
                    </Switch>
                }
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    let redirect = getRedirect(state);
    return {
        user: getUser(state),
        menuItems: getMenuItems(state),
        menuItemsFetching: isMenuItemsFetching(state),
        redirectPath: redirect,
        mobileOpen: getMobileOpen(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    redirect: path => dispatch(setRedirect(path)),
    setMobileOpen: open => dispatch(setMobileOpen(open)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
