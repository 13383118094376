import { denormalize, normalize } from "normalizr";
import qs from 'qs';
import { createAction, handleActions } from "redux-actions";
import createRemoteAction from '../createRemoteAction';
import { blockedTestDriveTimeSlotsList, inquiryItem, inquiryList, inquiryMessagesList } from "../schema";

export const inquiries = createRemoteAction({
    type: 'ENQUIRIES',
    url: ({dealer, ...params}) =>  '/api/dealers/'+ dealer.id + '/inquiries?'+ qs.stringify(params, {arrayFormat: 'bracket', encode: false, skipNulls: true}),
    schema: inquiryList,
});

export const consumerInquiries = createRemoteAction({
    type: 'CONSUMER_ENQUIRIES',
    url: ({consumer, ...params}) => `/api/consumers/${consumer.id}/inquiries?${qs.stringify(params, {arrayFormat: 'bracket', encode: false, skipNulls: true})}`,
    schema: inquiryList
});

export const inquiryAssign = createRemoteAction({
    type: 'INQUIRY_ASSIGN',
    method: 'PUT',
    url: props => {
        if ('' === props.data.assignedEmployee) {
            props.data.assignedEmployee = null;
        }

        return `/api/inquiries/${props.context.inquiry.id}/assign`
    },
    schema: inquiryItem
});

export const inquiryUpdate = createRemoteAction({
    type: 'CHANGE_INQUIRY_DETAILS',
    method: 'PUT',
    url: props => `/api/inquiries/${props.context.inquiryId}`,
    schema: inquiryItem,
});

export const inquiryCancel = createRemoteAction({
    type: 'CANCEL_INQUIRY',
    method: 'PUT',
    url: props => `/api/inquiries/${props.context.inquiryId}/cancel`,
    schema: inquiryItem,
});

export const inquiryUpdateTimeslot = createRemoteAction({
    type: 'INQUIRY_UPDATE_TIMESLOT',
    method: 'PUT',
    url: props => `/api/inquiries/${props.context.inquiryId}/timeslot`,
    schema: inquiryItem,
});

export const inquiryConvertToTestDrive = createRemoteAction({
    type: 'CONVERT_TO_TEST_DRIVE',
    method: 'PUT',
    url: props => `/api/inquiries/${props.context.inquiryId}/convert-to-testdrive`,
    schema: inquiryItem,
});

export const vehicleGetBlockedTimeslots = createRemoteAction({
    type: 'VEHICLE_GET_BLOCKED_TIMESLOTS',
    method: 'GET',
    url: props => `/api/vehicles/${props.stockVehicleId}/blocked-testdrives/${props.desiredAt}`,
    headers: {
        'Authorization': undefined
    },
    schema: blockedTestDriveTimeSlotsList,
});

export const singleInquiryValid = createRemoteAction({
    type: 'INQUIRY_VALID',
    method: 'GET',
    url: props => `/api/inquiries/${props.data.inquiryId}/valid/${props.data.cancelToken}`,
    headers: {
        'Authorization': undefined
    }
});

export const getSingleInquiry = createRemoteAction({
    type: 'GET_INQUIRY',
    method: 'GET',
    url: props => {
        return `/api/inquiries/${props.data.id}?token=${props.context.guestToken || ''}&lastName=${props.data.lastName || ''}`
    },
    headers: {
        'Authorization': undefined
    },
    schema: inquiryItem,
});

export const inquiryGuestConsumerUpdate = createRemoteAction({
    type: 'PUT_INQUIRY_GUEST',
    method: 'PUT',
    url: props => {
        return `/api/inquiries/${props.context.inquiryId}?token=${props.context.guestToken}&lastName=${props.context.lastName}`
    },
    headers: {
        'Authorization': undefined
    },
    schema: inquiryItem,
});

export const inquiryReply = createRemoteAction({
    type: 'INQUIRY_REPLY',
    method: 'POST',
    url: (props) => {
        if(props?.context?.guestToken && props?.context?.lastName)
            return `/api/inquiry-messages?token=${props.context.guestToken}&lastName=${props.context.lastName}`
        else
            return `/api/inquiry-messages`
    },
    extraStateManagement: (response) => {
        if (response?.data) {
            let inquiryId = response.props.data.inquiry.split('/').slice(-1).pop();
            let inquiry = {
                id: inquiryId,
                messages: [response.data]
            };
            return normalize(inquiry, inquiryItem);
        }
    },
    headers:props=> {
        if(props?.context?.guestToken)
            return {'Authorization': undefined}
    },
    forceArrayPush: true,
    forceArrayPushForField: 'messages'
});

export const inquiryMessages = createRemoteAction({
    type: 'INQUIRY_MESSAGES',
    method: 'GET',
    url: props => {
        if (props?.context?.guestToken && props?.context?.lastName)
            return `/api/inquiries/${props.context.inquiryId}/messages?pagination=false&token=${props.context.guestToken}&lastName=${props.context.lastName}`
        else
            return `/api/inquiries/${props.context.inquiryId}/messages?pagination=false`
    },
    extraStateManagement: (response) => {
        if (response?.data) {
            let inquiryId = response.props.context.inquiryId;
            let inquiry = {
                id: inquiryId,
                messages: response.data
            };
            return normalize(inquiry, inquiryItem);
        }
    },
    headers: props=> {
        if (props.context.guestToken)
            return {'Authorization': undefined}
    }
});

export const resendConfirmation = createRemoteAction({
    type: 'INQUIRY_RESEND_CONFIRMATION',
    method: 'PUT',
    url: props => `/api/inquiries/${props.inquiry.id}/resend-confirmation`,
});

export const inquiryLogs = createRemoteAction({
    type: 'INQUIRY_LOGS',
    method: 'GET',
    url: props => `/api/logs?objectClass=App%5CEntity%5CInquiry&objectId=${props.id}`
});

export const clearInquiriesList = createAction("CLEAR_INQUIRIES");

const initialState = {
    ids: [],
    fetching: false,
    fetchingCancelInquiry: false,
    fetchingResendConfirmation: false,
    cancelInquiry: false,
    fetchingSingleInquiryValid: false,
    fetchingAssign: false,
    submitting: false,
    fetchingUpdateCustomerDetails: false,
    fetchingUpdateTimeslot: false,
    fetchingBlockedTimeslots: false,
    submittingStatus: false,
    error: null,
    count: 0,
    submittingReply: false,
    fetchingMessages: false,
    availableTimeslotIds: [],
    blockedTimeslotIds: []
};

export default handleActions({
    [inquiries.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [inquiries.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
        count: action.error ? 0 : action.payload.count
    }),
    [consumerInquiries.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [consumerInquiries.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
        count: action.error ? 0 : action.payload.count
    }),
    [inquiryCancel.begin]: state => ({
        ...state,
        fetchingCancelInquiry: true,
    }),
    [inquiryCancel.request]: (state, action) => ({
        ...state,
        fetchingCancelInquiry: false,
        cancelInquiry: (action.payload.status && action.payload.status === 200) ? true : false
    }),
    [getSingleInquiry.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [getSingleInquiry.request]: (state, action) => ({
        ...state,
        fetching: false,
        ids: action.error ? state.ids : [action.payload.normalized.result],
    }),
    [singleInquiryValid.begin]: state => ({
        ...state,
        fetchingSingleInquiryValid: true,
    }),
    [singleInquiryValid.request]: state => ({
        ...state,
        fetchingSingleInquiryValid: false
    }),
    [resendConfirmation.begin]: state => ({
        ...state,
        fetchingResendConfirmation: true,
    }),
    [resendConfirmation.request]: state => ({
        ...state,
        fetchingResendConfirmation: false
    }),
    [inquiryAssign.begin]: state => ({
        ...state,
        fetchingAssign: true,
    }),
    [inquiryAssign.request]: state => ({
        ...state,
        fetchingAssign: false
    }),
    [inquiryReply.begin]: state => ({
        ...state,
        submittingReply: true,
    }),
    [inquiryReply.request]: (state, action) => ({
        ...state,
        submittingReply: false
    }),
    [inquiryMessages.begin]: state => ({
        ...state,
        fetchingMessages: true,
    }),
    [inquiryMessages.request]: (state, action) => ({
        ...state,
        fetchingMessages: false,
        error: action.error,
    }),
    [inquiryUpdate.begin]: state => ({
        ...state,
        submitting: true,
    }),
    [inquiryUpdate.request]: (state, action) => ({
        ...state,
        submitting: false,
    }),
    [inquiryGuestConsumerUpdate.begin]: state => ({
        ...state,
        submitting: true,
    }),
    [inquiryGuestConsumerUpdate.request]: (state, action) => ({
        ...state,
        submitting: false,
    }),
    [inquiryUpdateTimeslot.begin]: state => ({
        ...state,
        fetchingUpdateTimeslot: true,
    }),
    [inquiryUpdateTimeslot.request]: (state) => ({
        ...state,
        fetchingUpdateTimeslot: false,
    }),
    [inquiryConvertToTestDrive.begin]: state => ({
        ...state,
        fetchingUpdateTimeslot: true,
    }),
    [inquiryConvertToTestDrive.request]: inquiryConvertToTestDrive.deleteReducer('fetchingUpdateTimeslot'),

    [vehicleGetBlockedTimeslots.begin]: state => ({
        ...state,
        fetchingBlockedTimeslots: true,
    }),
    [vehicleGetBlockedTimeslots.request]: (state, action) => ({
        ...state,
        blockedTimeslotIds: action.error ? state.ids : action.payload.normalized.result,
        fetchingBlockedTimeslots: false,
    }),
    [clearInquiriesList]: state => ({
        ...state,
        ids:[]
    }),
    [inquiryLogs.begin]: state => ({
        ...state,
        fetchingLogs: true,
    }),
    [inquiryLogs.request]: state => ({
        ...state,
        fetchingLogs: false,
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getInquiries = (state, entities) => denormalize(state.ids, inquiryList, entities);
export const getInquiry = (id, entities) => denormalize(id, inquiryItem, entities);
export const getTotalInquiries = state => state.count;
export const getTotalCountByTestDrive = state => state.count;
export const getTotalCountByFinanceOrLease = state => state.count;
export const isFetchingInquiries = state => state.fetching;
export const isFetchingCancelInquiry = state => state.fetchingCancelInquiry;
export const isFetchingAssignInquiry = state => state.fetchingAssign;
export const isFetchingSingleInquiryValid = state => state.fetchingSingleInquiryValid;
export const isFetchingResendConfirmation = state => state.fetchingResendConfirmation;
export const inquiryCancelled = state => state.cancelInquiry;
export const getStatusSubmitting = state => state.submitting;
export const isInquiriesEditFetching = state => state.submitting;
export const isInquiriesUpdateTimeslotFetching = state => state.fetchingUpdateTimeslot;
export const IsVehicleBlockedTimeslotsFetching = state => state.fetchingBlockedTimeslots;
export const getInquiryMessages = (state, entities) => denormalize(state.inquiryMessagesIds, inquiryMessagesList, entities);
export const isSubmittingInquiryReply = state => state.submittingReply;
export const isFetchingInquiryMessages = state => state.fetchingMessages;
export const isFetchingInquiry = state => state.fetching;
export const getBlockedTimeSlots = (state, entities) => denormalize(state.blockedTimeslotIds, blockedTestDriveTimeSlotsList, entities);
export const isInquiryLogsFetching = state => state.fetchingLogs;
