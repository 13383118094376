import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';

import { Provider } from 'react-redux';
import store from './state/store';
import * as api from './api';
import {LocalizeProvider} from "react-localize-redux";

api.configure(store.getState, store.dispatch, window.location.origin);
store.subscribe(api.onStateChange);

ReactDOM.render(
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <App />
        </LocalizeProvider>
    </Provider>,
    document.getElementById('root')
);
