import de_DE from './de_DE.json';
import dateDe from 'date-fns/locale/de';
import en_GB from './en_GB.json';
import dateEn from 'date-fns/locale/en-GB';
import fr_BE from './fr_BE.json';
import dateFr from 'date-fns/locale/fr';
import nl_BE from './nl_BE.json';
import dateNl from 'date-fns/locale/nl';
import es_ES from './es_ES.json';
import dateES from 'date-fns/locale/es';

export default {
    fallbackLocale: 'en_GB',
    locales: {
        en_GB: "English",
        de_DE: "Deutsch",
        nl_BE: "Nederlands",
        fr_BE: "Français",
        es_ES: "Spanish",
    },
    translations: {
        de_DE,
        en_GB,
        fr_BE,
        nl_BE,
        es_ES,
    },
    dates: {
        de_DE: dateDe,
        en_GB: dateEn,
        fr_BE: dateFr,
        nl_BE: dateNl,
        es_ES: dateES,
    }
};
