import createRemoteAction from '../createRemoteAction'
import {menuItemList} from "../schema";
import {createAction, handleActions} from "redux-actions";
import {denormalize} from "normalizr";

export const menu = createRemoteAction({
    type: 'MENU_ITEMS',
    url: '/api/menu_items',
    schema: menuItemList,
});

export const setActiveMenuItem = createAction('MENUITEMS_SET_ACTIVE_ITEM');
export const setMobileOpen = createAction('MENU_SET_MOBILE_OPEN');

//export const addMenuItems = createAction('MENU_ADD_ITEMS');

const initialState = {
    fetching: false,
    error: {},
    ids: [],
    activeItemId: null,
    mobileOpen: false,
};

export default handleActions({
    [menu.begin]: (state, action) => ({
        ...state,
        fetching: true,
    }),
    [menu.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error ? true : state.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
    }),
    /*
    [addMenuItems]: (state, action) => ({
        ...state,
        ids: [...state.ids, ...action.payload.normalized.result],
    }),
     */
    [setActiveMenuItem]: (state, action) => ({
        ...state,
        activeItemId: !!action.payload.allowToggle && state.activeItemId === action.payload.id ? null : action.payload.id,
    }),
    [setMobileOpen]: (state, action) => ({
        ...state,
        mobileOpen: action.payload,
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getMenuItems = (state, entities) => denormalize(state.ids, menuItemList, entities).sort((a, b) => (a.position || 0) > (b.position || 0) ? 1 : -1);
export const isMenuItemsFetching = state => state.fetching;
export const getActiveMenuItemId = state => state.activeItemId;
export const getMobileOpen = state => state.mobileOpen;

