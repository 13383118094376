import axios from "axios";
import {createAction} from "redux-actions";
import {normalize} from "normalizr";

export default config => {

    if (!config.type) {
        throw 'missing config.type';
    }

    const action = {
        begin: createAction(config.type + '_BEGIN'),
        request: createAction(config.type + '_REQUEST', async props => {
            let url, method, headers, configHeader;
            try {
                if (props.url) {
                    url = props.url
                } else if (typeof config.url === 'function') {
                    url = config.url(props);
                } else {
                    url = config.url;
                }

                if (typeof config.headers === 'function') {
                    configHeader = config.headers(props);
                } else {
                    configHeader = config.headers;
                }

                if (!!props.method) {
                    method = props.method;
                } else if (!!config.method) {
                    method = config.method;
                } else {
                    method = 'get';
                }

                if (typeof props.headers !== 'object') {
                    props.headers = {};
                }

                headers = {
                    ...axios.defaults.headers.common,
                    ...configHeader,
                    ...props.headers
                };

                config.source = axios.CancelToken.source();

            } catch (e) {
                return new Promise((resolve, reject) => {
                    reject({
                        config,
                        props,
                        error: e,
                    });
                });
            }

            let data = null;
            if (["POST", "PUT"].indexOf(method) !== -1) {
                data = props.data;
            }
            let axiosRequestConfig = {
                url,
                method,
                headers,
                data,
                cancelToken: config.source.token,
                noRefreshToken: config.noRefreshToken,
            };

            if (config.responseType) {
                axiosRequestConfig.responseType = config.responseType;
            }

            return axios.request(axiosRequestConfig).then(remote => {
                let response;
                try {
                    //TODO: need to test this with Andreas
                    const isListResponse = !!remote.data['hydra:member'] || !!remote.data.data;
                    let data = remote.data['hydra:member'] || remote.data.data || remote.data;
                    if (data) {
                        if (typeof props.map === 'function')
                            data = isListResponse
                                ? data.map(item => props.map(item, props, config))
                                : props.map(data, props, config);

                        if (typeof config.map === 'function')
                            data = isListResponse
                                ? data.map(item => config.map(item, props, config))
                                : config.map(data, props, config);

                        if (!!config.schema) {
                            response = {
                                config,
                                props,
                                normalized: normalize(data, config.schema),
                                data,
                            };
                        } else {
                            response = {
                                config,
                                props,
                                data,
                                headers: remote.headers,
                            };
                        }

                        if (isListResponse)
                            response.count = remote.data['hydra:totalItems'] || remote.data.total || 0;
                    } else {
                        response = {
                            config,
                            props,
                            data: props.data,
                        };
                    }

                    if ('function' === typeof config.extraStateManagement && !config.schema) {
                        response['normalized'] = config.extraStateManagement(response)
                    }

                    if (!response.status) {
                        response.status = remote.status;
                    }

                } catch (e) {
                    return new Promise((resolve, reject) => {
                        reject({
                            config,
                            props,
                            error: e,
                        });
                    });
                }

                return Promise.resolve(response);

            }).catch(error => {
                return Promise.reject({
                    config,
                    props,
                    error,
                });
            });
        }),
        cancel: message => {
            if (config.source) {
                config.source.cancel(message)
            }
        },
        action: props => (dispatch, getState) => {
            if (!props) props = {};
            dispatch(action.begin(props));
            return dispatch(action.request(props)).then(action => action.payload);
        },
        deleteReducer: (fetchingField = 'fetching', field = 'ids') => (state, action) => {

            const newState = {
                ...state,
                [fetchingField]: false,
            };

            if (action.error)
                return newState;

            const idToRemove = action.payload.props.data.id;
            const index = newState[field].indexOf(idToRemove);
            if (index !== -1)
                delete newState[field][index];

            return newState;
        },
    };

    return action;
};