import AbstractEntity from './AbstractEntity';

class Order extends AbstractEntity
{
    static PAYMENT_STATUS_WAITING = 'waiting';
    static PAYMENT_STATUS_PAID = 'paid';
    static PAYMENT_STATUS_UNPAID = 'unpaid';
    static PAYMENT_STATUS_PENDING = 'pending';
    static PAYMENT_STATUS_REFUNDED = 'refunded';

    static FINANCE_STATUS_WAITING = 'waiting';
    static FINANCE_STATUS_APPROVED = 'approved';

    static TYPE_RESERVE = 'reserve';
    static TYPE_FINANCE = 'finance';
    static TYPE_PAY_IN_FULL = 'pay_in_full';

    static STATUS_NEW = 'new';
    static STATUS_INITIATED = 'initiated';
    static STATUS_TYPE_SELECTED = 'type_selected';
    static STATUS_PART_EXCHANGE_COMPLETED = 'part_exchange_completed';
    static STATUS_TERMS_AND_CONDITIONS = 'terms_and_conditions_accepted';
    static STATUS_FINANCE_INITIATED = 'finance_initiated';
    static STATUS_FINANCE_COMPLETED = 'finance_completed';
    static STATUS_DOCUSIGN_INITIATED = 'docusign_envelope_initiated';
    static STATUS_DOCUSIGN_COMPLETED = 'docusign_envelope_signed';
    static STATUS_PAYMENT_INITIATED = 'payment_initiated';
    static STATUS_PAYMENT_COMPLETED = 'payment_completed';
    static STATUS_PROCESSING = 'processing';
    static STATUS_FULFILLED = 'fulfilled';
    static STATUS_CANCELLED = 'cancelled';

    // states that require consumer action
    static statusMap = {
        [Order.STATUS_NEW]: 'action',
        [Order.STATUS_INITIATED]: 'action',
        [Order.STATUS_TYPE_SELECTED]: 'action',
        [Order.STATUS_PART_EXCHANGE_COMPLETED]: 'action',
        [Order.STATUS_TERMS_AND_CONDITIONS]: 'action',
        [Order.STATUS_FINANCE_INITIATED]: 'action',
        [Order.STATUS_FINANCE_COMPLETED]: 'action',
        [Order.STATUS_DOCUSIGN_INITIATED]: 'action',
        [Order.STATUS_DOCUSIGN_COMPLETED]: 'action',
        [Order.STATUS_PAYMENT_INITIATED]: 'action',
        [Order.STATUS_PAYMENT_COMPLETED]: 'pending',
        [Order.STATUS_FULFILLED]: 'fulfilled',
        [Order.STATUS_PROCESSING]: 'processing',
        [Order.STATUS_CANCELLED]: 'cancelled',
    };

    // states that allow to cancel the order
    static cancellableStates = [
        Order.STATUS_NEW,
        Order.STATUS_INITIATED,
        Order.STATUS_TYPE_SELECTED,
        Order.STATUS_PART_EXCHANGE_COMPLETED,
        Order.STATUS_TERMS_AND_CONDITIONS,
        Order.STATUS_FINANCE_INITIATED,
        Order.STATUS_FINANCE_COMPLETED,
        Order.STATUS_DOCUSIGN_INITIATED,
        Order.STATUS_DOCUSIGN_COMPLETED,
        Order.STATUS_PAYMENT_INITIATED,
        Order.STATUS_PAYMENT_COMPLETED,
        Order.STATUS_PROCESSING,
    ];

    status = null;
    docusignEnvelope = null;
    orderItems = [];
    payments = [];

    /**
     * For now there is only one product, so just return the only product as the Vehicle
     * @returns {Product}
     */
    getMainItem = () => this.orderItems[0];

    /**
     * Backend allows to have multiple Payment records, use the last payment as the current one
     * @returns {object}
     */
    getPayment = () => {
        let payments = this.payments.filter(payment => "payment_provider" == this.dealer.getModuleType(payment.moduleName));
        if (payments.length > 0) {
            return payments[payments.length - 1];
        }
    }

    getPaymentStatus = () => {
        const payment = this.getPayment();
        if (payment)
            return payment.status;

        return Order.PAYMENT_STATUS_PENDING;
    }

    /**
     * Backend allows to have multiple Finance records, use the last finance as the current one
     * @returns {object}
     */
    getFinance = () => {
        let finances = this.payments.filter((payment) => "finance_provider" === this.dealer.getModuleType(payment.moduleName));
        if (finances.length > 0)
            return finances[finances.length - 1];

        return null;
    }

    /**
     * @returns {null|*}
     */
    getPartExchange = () => {
        let partExchange = this.orderItems.filter((orderItem) => "part_exchange" === orderItem.itemType);

        if (partExchange.length > 0)
            return partExchange[partExchange.length - 1];

        return null;
    }

    getAmountToChargeFromPaymentProvider = () => {
        let amountToCharge = null;

        if (Order.TYPE_FINANCE === this.type) {
            amountToCharge = this.getFinance()?.depositInCents;
        } else {
            amountToCharge = this.getVehicle()?.dealer.getReservationFee() ?? 0;
        }

        return (amountToCharge/100).toFixed(2).toString();
    }

    getTranslationForOrderItemDescription = (translate) => {
        if ('undefined' === typeof translate) {
            return "";
        }
        let translation = null;

        if (Order.TYPE_FINANCE === this.type) {
            translation = translate('digitalRetail.order.payment.paypal.deposit_amount_vehicle');
        } else {
            translation = translate('digitalRetail.order.payment.paypal.amount_for_vehicle_item');
        }

        return translation
    }

    getVehicle = () => this.getMainItem()?.product;
    getDisplayedStatus = () => Order.statusMap[this.status];
    isCancellable = () => Order.cancellableStates.includes(this.status);
    isContractSigned = () => this.docusignEnvelope?.status === 'completed';
    isReservation = () => this.type === Order.TYPE_RESERVE;
    isFinance = () => this.type === Order.TYPE_FINANCE;
}

export default Order;
