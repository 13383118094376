import AbstractEntity from './AbstractEntity';
import {convertToHTML} from 'draft-convert';
import {convertFromRaw} from 'draft-js';
import {Translate} from "react-localize-redux";

class Dealer extends AbstractEntity
{

    static schemaKey =  'dealers';

    static moduleTitles = {
        "inquiries": "digitalRetail.dealer.module_config.inquiries",
        "orders": "digitalRetail.dealer.module_config.orders",
        "reserve": "digitalRetail.dealer.module_config.orders_submodules.reserve",
        "pay_in_full": "digitalRetail.dealer.module_config.orders_submodules.pay_in_full",
        "terms_and_conditions": "digitalRetail.dealer.module_config.orders_submodules.terms_and_conditions",
        "stripe": "digitalRetail.dealer.module_config.orders_submodules.stripe",
        "docusign": "digitalRetail.dealer.module_config.orders_submodules.docusign",
        "codeweavers": "digitalRetail.dealer.module_config.orders_submodules.codeweavers",
        "eva": "digitalRetail.dealer.module_config.orders_submodules.eva",
        "google_analytics": "digitalRetail.dealer.module_config.google_analytics",
        "google_tag_manager": "digitalRetail.dealer.module_config.google_tag_manager",
    }

    static moduleWithTheirTypes = {
        "stripe": "payment_provider",
        "codeweavers": "finance_provider",
        "eva": "part_exchange_provider"
    };

    getModuleType = moduleName => {
        if (this.moduleConfig[moduleName] && this.moduleConfig[moduleName]["type"]) {
            return this.moduleConfig[moduleName]["type"];
        }

        for (let n in this.moduleConfig) {
            const module = this.moduleConfig[n];
            if (true === module['enabled'] &&
                module['submodules'] &&
                module['submodules'][moduleName] &&
                module['submodules'][moduleName]['type'])
                return module['submodules'][moduleName]['type'];
        }

        return null;
    }

    isModuleEnabled = moduleName => {
        if (this.moduleConfig[moduleName] && true === this.moduleConfig[moduleName]['enabled'])
            return true;

        for (let n in this.moduleConfig) {
            const module = this.moduleConfig[n];
            if (true === module['enabled'] &&
                module['submodules'] &&
                module['submodules'][moduleName] &&
                true === module['submodules'][moduleName]['enabled'])
                return true;
        }

        return false;
    }

    getModuleTitle = module => Dealer.moduleTitles[module] || module;

    getEnabledModules = () => {
        const enabledModules = [];
        for (let module in this.moduleConfig) {
            if (this.isModuleEnabled(module)) {
                enabledModules.push(module);
            }
        }

        return enabledModules.join(', ');
    }

    getEnabledModulesList = (translate, excludeModuleTypes = []) => {
        const enabledModules = [];
        for (let module in this.moduleConfig) {
            if (this.isModuleEnabled(module)) {
                let moduleName = this.getModuleTitle(module);
                if ('function' === typeof translate)
                    moduleName = translate(moduleName);
                enabledModules.push(moduleName);

                this.moduleConfig[module]['submodules'] &&
                Object.keys(this.moduleConfig[module]['submodules']).forEach((submodule) => {
                    if (this.isModuleEnabled(submodule) && 'function' === typeof translate) {
                        if (Array.isArray(excludeModuleTypes) && 0 < excludeModuleTypes.length &&
                            !excludeModuleTypes.includes(this.moduleConfig[module]['submodules'][submodule]['type'])) {
                            let subModuleName = this.getModuleTitle(submodule);
                            subModuleName = translate(subModuleName);
                            enabledModules.push(subModuleName);
                        }
                    }
                });
            }

        }

        return enabledModules.join(', ');
    }

    getEnabledProviders = (moduleType) => {
        const providers = [];
        if (true === this.moduleConfig?.orders?.enabled && this.moduleConfig?.orders?.submodules) {
            for (let key in this.moduleConfig['orders']['submodules']) {
                const module = this.moduleConfig['orders']['submodules'][key];
                if (true === module['enabled'] && moduleType === module['type']) {
                    module.key = key;
                    providers.push(module);
                }
            }
        }

        return providers;
    };

    getEnabledPaymentProviders = () => this.getEnabledProviders('payment_provider');

    getEnabledFinanceProviders = () => this.getEnabledProviders('finance_provider');

    getEnabledPartExchangeProviders = () => this.getEnabledProviders('part_exchange_provider');

    isAnyPaymentProviderEnabled = () => {
        let enabledPaymentProviders = this.getEnabledPaymentProviders();
        return Array.isArray(enabledPaymentProviders) && 0 < enabledPaymentProviders.length;
    }

    isAnyFinanceProviderEnabled = () => {
        let enabledFinanceProviders = this.getEnabledFinanceProviders();
        return Array.isArray(enabledFinanceProviders) && 0 < enabledFinanceProviders.length;
    }

    isAnyPartExchangeProviderEnabled = () => {
        let enabledPartExchangeProviders = this.getEnabledPartExchangeProviders();
        return Array.isArray(enabledPartExchangeProviders) && 0 < enabledPartExchangeProviders.length;
    }

    getReservationFee = () => this.moduleConfig.orders.params.reservationFeeInCents;

    IsMoreThenOneOrderTypeEnable = () => {
        return 1 < this.getAvailableOrderTypes().length;
    }

    getAvailableOrderTypes = () => {
        const orderTypes = [];
        if (true === this.moduleConfig?.orders?.enabled && this.moduleConfig?.orders?.submodules) {
            for (let key in this.moduleConfig['orders']['submodules']) {
                const module = this.moduleConfig['orders']['submodules'][key];
                if (true === module['enabled'] && "undefined" !== typeof module['order_type']) {
                    orderTypes.push({
                        label: <Translate id={`digitalRetail.dealer.module_config.orders_submodules.${module['order_type']}`} />,
                        value: module.order_type
                    });
                }
            }
        }

        return orderTypes;
    };

    getTermsAndConditionByLocale = (locale) => {

        if ("undefined" !== typeof this.termsAndConditions[locale] &&
            null !== this.termsAndConditions[locale] &&
            "" !== this.termsAndConditions[locale]) {
            return convertToHTML(convertFromRaw(JSON.parse(this.termsAndConditions[locale])));
        }

        return null;
    }

    areTermsAndConditionsComplete = (allLocales) => {
        if (!this.termsAndConditions || 0 === this.termsAndConditions.length) {
            return false;
        }

        const locales = this.getLocales(allLocales);

        let complete = true;
        locales.forEach(locale => {
            if ("undefined" === typeof this.termsAndConditions[locale] ||
                null === this.termsAndConditions[locale] ||
                "" === this.termsAndConditions[locale]) {
                complete = false;
            }
        });

        return complete;
    }

    getLocalesForMissingTermsAndConditions = (allLocales) => {
        const locales = this.getLocales(allLocales);

        if (!this.termsAndConditions || 0 === this.termsAndConditions.length) {
            return locales;
        }

        let missingLocales = [];
        locales.forEach(locale => {
            if ("undefined" === typeof this.termsAndConditions[locale] ||
                null === this.termsAndConditions[locale] ||
                "" === this.termsAndConditions[locale]) {
                missingLocales.push(locale);
            }
        })

        return missingLocales;
    }

    getLocales = (allLocales) => {
        let locales = this.locales;
        if (!locales || 0 === locales.length) {
            locales = Object.keys(allLocales);
        }
        return locales;
    }

    getPaypalClientID = () => {
        return this.moduleConfig.orders.submodules?.paypal?.params?.client_id ?? '';
    }
}

export default Dealer;
