import React from "react";
import styled, { withTheme } from "styled-components";
import LanguageMenu from "../General/LanguageMenu";

import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import DealerSelect from "../../containers/General/DealerSelect";
import UserMenu from "../../containers/Layout/UserMenu";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Header = ({onDrawerToggle }) => {

  return (
      <React.Fragment>
        <AppBar position="sticky" elevation={0}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item>
                <DealerSelect />
              </Grid>
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={onDrawerToggle}
                  >
                    <MenuIcon/>
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item xs/>
              <Grid item>
                <LanguageMenu/>
              </Grid>
              <Grid item>
                <UserMenu/>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
  );
};

export default withTheme(Header);
