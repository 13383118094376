import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";
import {LinearProgress, withStyles} from '@material-ui/core';
import { Translate } from "react-localize-redux";
import packageJson from '../../../package.json';

import {
  Collapse,
  Chip,
  Divider,
  ListItem,
  ListItemText,
  Drawer as MuiDrawer,
  List as MuiList,
  Typography,
  Icon,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import {connect} from "react-redux";
import {getActiveMenuItemId, getMenuItems, getMobileOpen, getUser, isMenuItemsFetching} from "../../state/root";
import UserInfo from "../../components/Users/UserInfo";
import {setActiveMenuItem} from "../../state/menu";
import {setRedirect} from "../../state/modix";

const NavLink = React.forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Version = styled(Typography)`
  position: absolute;
  bottom: ${props => props.theme.spacing(1)}px;
  left: ${props => props.theme.spacing(2)}px;
  font-size: 12px;
  color: ${props => props.theme.palette.grey["400"]}
`;

const HeaderTitle = styled(Typography)`
  margin-left: ${props => props.theme.spacing(2)}px;
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${props => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${props => props.theme.spacing(2.5)}px;
  padding-bottom: ${props => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  color: ${props => props.theme.sidebar.header.color};
  background-color: ${props => props.theme.sidebar.header.background};
  font-family: ${props => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(6)}px;

  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${props => props.theme.spacing(3)}px;
  padding-bottom: ${props => props.theme.spacing(3)}px;
  padding-left: ${props => props.theme.spacing(6)}px;
  padding-right: ${props => props.theme.spacing(5)}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  border-left: 3px solid transparent;

  svg {
    color: ${props => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    color: ${props => props.theme.palette.primary.main};
    border-left: 3px solid ${props => props.theme.palette.primary.main};
    background-color: #fff;
  }

  &.${props => props.activeClassName} {
    color: ${props => props.theme.palette.primary.main};
    border-left: 3px solid ${props => props.theme.palette.primary.main};
    background-color: #fff;
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${props => props.theme.sidebar.color};
    font-size: ${props => props.theme.typography.body1.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    padding: 0 ${props => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${props => props.theme.spacing(16)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
  padding-bottom: ${props => props.theme.spacing(2)}px;
  border-left: 3px solid transparent;

  &:hover {
    color: ${props => props.theme.palette.primary.main};
    border-left: 3px solid ${props => props.theme.palette.primary.main};
    background-color: #fff;
  }

  &.${props => props.activeClassName} {
    color: ${props => props.theme.palette.primary.main};
    border-left: 3px solid ${props => props.theme.palette.primary.main};
    background-color: #fff;
  }
`;

const LinkText = styled(ListItemText)`
  color: ${props => props.theme.sidebar.color};
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${props => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${props => props.theme.sidebar.badge.color};
    padding-left: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  padding: ${props => props.theme.spacing(2)}px
    ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  return (
    <Category {...rest}>
      {icon && <Icon>{icon}</Icon>}
      <CategoryText> <Translate id={name} /></CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}

function SidebarLink({ name, to, icon, badge, onClick, visible }) {
  return (
      visible && <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      onClick={onClick}
      visible
    >
      {icon && <Icon>{icon}</Icon>}
      <LinkText> <Translate id={name} /></LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    const pathName = this.props.location.pathname;
    for (let route of props.menuItems) {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;
      if (isActive || isOpen || isHome) {
        props.setActiveMenuItem(route);
        break;
      }

      if (route.children) {
        for (let childRoute of route.children) {
          const isActive = pathName.indexOf(childRoute.path) === 0;
          if (isActive) {
            props.setActiveMenuItem(route);
            break;
          }
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {menuItems, menuItemsFetching, activeItemId, user, mobileOpen} = this.props;

    if (mobileOpen !== nextProps.mobileOpen)
      return true;

    if (menuItemsFetching !== nextProps.menuItemsFetching)
      return true;

    if (menuItems.length !== nextProps.menuItems.length)
      return true;

    if (activeItemId !== nextProps.activeItemId)
      return true;

    return !user && nextProps.user || user && !nextProps.user || user && nextProps.user && user.id !== nextProps.user.id;
  }

  render() {
    const { user, classes, staticContext, theme, menuItems, menuItemsFetching, dispatch, setActiveMenuItem, activeItemId, mobileOpen, ...other } = this.props;

    menuItems.sort((a, b) => (a.position || 0) > (b.position || 0) ? 1 : -1);

    return (
      <Drawer variant="permanent" {...other}>
        <Brand>
          {theme?.logos?.header && <img src={theme.logos.header} height="42" />}
          {theme.name && <HeaderTitle variant="h4">{theme.name}</HeaderTitle>}
        </Brand>
        <Scrollbar>
          {menuItemsFetching && <LinearProgress color="primary" />}
          <UserInfo user={user} />
          <Divider />
          <List disablePadding>
            <Items>
              {menuItems.map((category, index) => (
                <React.Fragment key={index}>
                  {category.header ? (
                    <SidebarSection variant="caption">
                      {category.header}
                    </SidebarSection>
                  ) : null}

                  {category.children && category.children.length ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={category.id === activeItemId}
                        isCollapsable={true}
                        name={category.name}
                        icon={category.icon}
                        button={true}
                        onClick={() => setActiveMenuItem({...category, allowToggle: 1 < menuItems.length})}
                      />

                      <Collapse
                        in={category.id === activeItemId}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map((route, index) => route.name ? (
                          <SidebarLink
                            key={index}
                            name={route.name}
                            to={route.path}
                            icon={route.icon}
                            badge={route.badge}
                            visible={route.visible ?? true}
                          />
                        ) : null)}
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    <SidebarCategory
                      isCollapsable={false}
                      name={category.name}
                      to={category.path}
                      activeClassName="active"
                      component={NavLink}
                      icon={category.icon}
                      button={true}
                      exact
                      badge={category.badge}
                    />
                  )}
                </React.Fragment>
              ))}
            </Items>
          </List>
          <Version>
            Version {packageJson.version}
          </Version>
        </Scrollbar>
      </Drawer>
    );
  }
}

const mapStateToProps = state => ({
  user: getUser(state),
  menuItems: getMenuItems(state),
  menuItemsFetching: isMenuItemsFetching(state),
  activeItemId: getActiveMenuItemId(state),
  mobileOpen: getMobileOpen(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setActiveMenuItem: menuItem => dispatch(setActiveMenuItem(menuItem)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles({}, { withTheme: true })(withRouter(Sidebar)));
