import createRemoteAction from '../createRemoteAction';
import {createAction, handleActions} from "redux-actions";
import bqs from '../buildQueryString';

export const dealerStats = createRemoteAction({
    type: 'DEALER_STATS',
    url: ({dealer, ...props}) =>  '/api/dealers/'+ dealer.id + '/stats?'+ bqs(props),
});

export const createReport = createRemoteAction({
    type: 'DEALER_REPORT',
    url: ({dealer, ...props}) =>  '/api/dealers/'+ dealer.id + '/report?'+ bqs(props),
});

export const selectInterval = createAction('DASHBOARD_SET_INTERVAL');

const initialState = {
    statsFetching: false,
    reportFetching: false,
    interval: 'dateToday',
    dealer: null,
};

export default handleActions({
    [dealerStats.begin]: state => ({
        ...state,
        dealer: null,
        statsFetching: true,
    }),
    [dealerStats.request]: (state, action) => ({
        ...state,
        statsFetching: false,
        dealer: action.payload.data,
    }),
    [selectInterval]: (state, action) => ({
        ...state,
        dealer: null,
        interval: action.payload,
    }),
    [createReport.begin]: state => ({
        ...state,
        reportFetching: true,
    }),
    [createReport.request]: (state, action) => ({
        ...state,
        reportFetching: false,
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const isDealerStatsFetching = state => state.statsFetching;
export const isReportFetching = state => state.reportFetching;
export const getDealerStatsInterval = state => state.interval;
export const getDealerStats = state => state.dealer;