import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import {Translate} from "react-localize-redux";

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${props => props.theme.breakpoints.up("md")} {
    padding: ${props => props.theme.spacing(10)}px;
  }
`;

const Welcome = ({
    user,
}) => (
    <Wrapper>
        <Typography component="h1" variant="h1" gutterBottom>
            <Translate id="welcomePageTitle" />, {user.firstname}
        </Typography>
    </Wrapper>
);

Welcome.propTypes = {
    user: PropTypes.object.isRequired,
};

export default Welcome;
