import createRemoteAction from '../createRemoteAction'
import {handleActions} from "redux-actions";

export const emailConfirm = createRemoteAction({
    type: "EMAIL_CONFIRM",
    method: "GET",
    url: props => "/api/email-change-requests/" + props.data.token,
    headers: {
        'Authorization': undefined
    }
});

const initialState = {
    fetchingEmailConfirm: false,
};

export default handleActions({
    [emailConfirm.begin]: state => ({
        ...state,
        fetchingEmailConfirm: true
    }),
    [emailConfirm.request]: state => ({
        ...state,
        fetchingEmailConfirm: false
    }),
}, initialState);

export const isEmailConfirmFetching = state => state.fetchingEmailConfirm;