export const defaultThemeColorConfig = {
  "primaryColor": "#FF5000",
  "secondaryColor": "#282828",
  "primaryContrastTextColor": "#FFFFFF",
  "secondaryContrastTextColor": "#FFFFFF",
  "headerBackgroundColor": "#FF5000",
  "headerTextColor": "#FFFFFF",
  "sidebarBackgroundColor": "#f0f0f0",
  "sidebarHeaderTextColor": "#FFFFFF",
  "sidebarHeaderBackgroundColor": "#FF5000",
  "bodyBackgroundColor": "#f0f0f0",
};

export const defaultThemeLogoConfig = {
  "logos": {
    "default": "/static/img/logo/modix-logo-dark.svg",
    "header": "/static/img/logo/modix-logo-white.svg",
    "lightBackground": "/static/img/logo/modix-logo-orange.svg"
  }
};
