import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk'
import promise from 'redux-promise';
import rootReducer from './root';

const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    /* preloadedState, */
    composeWithDevTools(applyMiddleware(thunk, promise)),
);

export default store;
