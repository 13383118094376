import async from "./components/General/Async";
import {Translate} from "react-localize-redux";
import React from "react";

export const currenciesMap = {
    'EUR': 'EUR',
    'GBP': 'GBP',
    'USD': 'USD'
};

export const socialLoginProviders = [
    {
        name: 'Google',
        label: <Translate id="digitalRetail.google" />
    },
    /*
    {
        name: 'microsoft',
        label: 'Microsoft'
    }
    */
];

export const themeProperties = [
    'primaryColor',
    'secondaryColor',
    'primaryContrastTextColor',
    'secondaryContrastTextColor',
    'headerTextColor',
    'headerBackgroundColor',
    'sidebarBackgroundColor',
    'sidebarHeaderTextColor',
    'sidebarHeaderBackgroundColor',
    'bodyBackgroundColor',
    'showDealerNameAtHeader'
];

export const logoTypes = [
    'headerLogo',
    'defaultLogo',
    'lightBackgroundLogo'
];

export const backendPaymentClassesMap = {
    "StripePayment": "Stripe",
    "PaypalPayment": "Paypal"
};

export const rolesMap = {
    user: {
        "ROLE_ADMIN": <Translate id="auth_service.users.role.admin" />,
        "ROLE_USER": <Translate id="auth_service.users.role.user" />,
    },
    dealer: {
        "ROLE_DEALER": <Translate id="auth_service.users.role.dealer" />,
        "ROLE_DEALERADMIN": <Translate id="auth_service.users.role.dealer_admin" />,
    },
};

export const rolesMapWithTranslationIdsOnly = {
    user: {
        "ROLE_ADMIN": "auth_service.users.role.admin",
        "ROLE_USER": "auth_service.users.role.user",
    },
    dealer: {
        "ROLE_DEALER": "auth_service.users.role.dealer",
        "ROLE_DEALERADMIN": "auth_service.users.role.dealer_admin",
    },
};

export const componentMap =  {
    'digital-retail-dashboard': async(() => import("./components/Dashboard/digitalRetail")),
    'digital-retail-inquiries': async(() => import("./containers/Inquiries/Inquiries")),
    'digital-retail-testdrives': async(() => import("./containers/Inquiries/TestDrives")),
    'digital-retail-dealer-settings': async(() => import("./containers/Dealers/Settings/Settings")),
    'digital-retail-inquiry-cancel-confirmation': async(() => import("./containers/Inquiries/InquiryCancelConfirmation")),
    'digital-retail-guest-inquiry': async(() => import("./containers/Inquiries/guestConsumer/Inquiry")),
    'auth-service-signin': async(() => import("./containers/Authentication/Signin")),
    'auth-service-email-confirm': async(() => import("./containers/Authentication/EmailConfirm")),
    'auth-service-accept-invite': async(() => import("./containers/Authentication/AcceptInvite")),
    'auth-service-signup': async(() => import("./containers/Authentication/Signup")),
    'auth-service-forgot-password': async(() => import("./containers/Authentication/ForgotPassword")),
    'auth-service-recover-password': async(() => import("./containers/Authentication/RecoverPassword")),
    'auth-service-dashboard': async(() => import("./components/Dashboard")),
    'auth-service-users' : async( () => import("./containers/Users/Index")),
    'auth-service-users-email-verify' : async( () => import("./containers/Users/EmailVerify")),
    'digital-retail-orders': async(() => import("./containers/Orders/Orders")),
    'digital-retail-my-orders': async(() => import("./containers/Orders/MyOrders")),
    'auth-service-dealers': async(() => import("./containers/Dealers/Index")),
    'auth-service-profile': async(() => import("./containers/Profile/ProfileTable")),
    'digital-retail-product-view': async(() => import("./containers/Products/VehicleProductView")),
    'digital-retail-order-signin-signup': async(() => import("./containers/Orders/SigninSignup")),
    'digital-retail-order-flow-type-choice': async(() => import("./containers/Orders/OrderTypeChoice")),
    'digital-retail-order-flow': async(() => import("./containers/Orders/OrderFlow")),
    'digital-retail-order-flow-finance': async(() => import("./containers/Orders/OrderFinanceProvider")),
    'digital-retail-order-flow-codeweavers-finance': async(() => import("./containers/Orders/OrderCodeweaversFinance")),
    'digital-retail-order-flow-part-exchange': async(() => import("./containers/Orders/OrderPartExchangeProvider")),
    'digital-retail-order-flow-eva-part-exchange': async(() => import("./containers/Orders/OrderEvaPartExchange")),
    'digital-retail-order-flow-terms-and-conditions': async(() => import("./containers/Orders/OrderTermsAndConditions")),
    'digital-retail-order-flow-docusign': async(() => import("./containers/Orders/OrderDocusignEnvelope")),
    'digital-retail-order-flow-stripe-payment': async(() => import("./containers/Orders/OrderStripePayment")),
    'digital-retail-order-flow-paypal-payment': async(() => import("./containers/Orders/OrderPaypalPayment")),
    'digital-retail-order-flow-payment-provider': async(() => import("./containers/Orders/OrderPaymentProvider")),
    'digital-retail-order-flow-complete': async(() => import("./containers/Orders/OrderComplete")),
};


export const protectedLayoutWithoutSidebar = [
    /*{
        path: "/digital-retail/orders/:orderId",
        exact: true,
        component: "digital-retail-consumer-order",
    },
    {
        path: "/digital-retail/order/:orderId?",
        exact: true,
        component: "digital-retail-order-flow"
    }*/
];

export const publicLayoutWithHeader = [
    {
        path: "/inquiry/:inquiryId/guest/:guestToken/:locale?",
        exact: true,
        component: "digital-retail-guest-inquiry"
    },
    {
        path: "/dealer/:stockDealerId/:providerKey/:stockVehicleId/signin/:quoteReference?",
        exact: true,
        component: "digital-retail-order-signin-signup",
        // TODO: this should become default behavior!
        hideHeaderBeforeThemeIsAvailable: true
    },
    {
        path: "/dealer/:stockDealerId/:providerKey/:stockVehicleId/:quoteReference?",
        exact: true,
        component: "digital-retail-product-view",
        // TODO: this should become default behavior!
        hideHeaderBeforeThemeIsAvailable: true
    }
];

export const publicRoutes =  [
    {
        path: "/inquiry/:inquiryId/cancel/confirmation/:cancelToken/:locale?",
        exact: true,
        component: "digital-retail-inquiry-cancel-confirmation"
    },
    {
        path: "/accept/invite/:locale/:dealerId/:invitationToken?",
        exact: true,
        component: 'auth-service-accept-invite',
    },
    {
        path: "/signup/:locale?",
        exact: true,
        component: 'auth-service-signup',
    },
    {
        path: "/login/password/forgot",
        exact: true,
        component: 'auth-service-forgot-password',
    },
    {
        path: "/login/password/recover/:lostPasswordToken/:locale?",
        exact: true,
        component: 'auth-service-recover-password',
    },
    {
        path: "/signin/:locale?",
        exact: true,
        component: 'auth-service-signin',
    },
    {
        path: "/email/confirm/:token/:locale?",
        exact: true,
        component: "auth-service-email-confirm"
    },
    {
        path: "/user/:userId/verify/:verifyToken",
        exact: true,
        component: "auth-service-users-email-verify"
    }
];
