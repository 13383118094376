import createRemoteAction from '../createRemoteAction';
import {employmentItem, employmentList} from "../schema";
import {createAction, handleActions} from "redux-actions";
import {denormalize} from "normalizr";
import bqs from '../buildQueryString';

export const employments = createRemoteAction({
    type: 'EMPLOYMENTS',
    url: ({dealerId,...params}) =>  {
        return `/api/dealers/${dealerId}/employments?${bqs(params)}`;
    },
    schema: employmentList,
});

export const addUserDealerAndEmployment = createRemoteAction({
    type: 'ADD_USER_DEALER_EMPLOYMENT',
    method: 'POST',
    url:  `/api/employments`,
});

export const updateUsersEmployment = createRemoteAction({
    type: 'UPDATE_USER_EMPLOYMENT',
    method: 'PUT',
    url: props => `/api/employments/${props.data.id}`,
    schema: employmentItem,
});

export const deleteUsersEmployment = createRemoteAction({
    type: 'DELETE_USER_EMPLOYMENT',
    method: 'Delete',
    url: props => `/api/employments/${props.data.id}`,
});

export const removeEmploymentsList = createAction("REMOVE_EMPLOYMENTS");

const initialState = {
    ids: [],
    fetching: false,
    fetchingEdit: false,
    fetchingDelete: false,
    isFetchingProfile: false,
    isUpdatingEmployment:false,
    isDeleteUsersEmployment:false,
    error: null,
    count: 0,
};

export default handleActions({
    [employments.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [employments.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
        count: action.error ? 0 : action.payload.count,
    }),
    [deleteUsersEmployment.begin]: state => ({
        ...state,
        isDeleteUsersEmployment: true,
    }),
    [deleteUsersEmployment.request]: state => ({
        ...state,
        isDeleteUsersEmployment: false,
    }),
    [removeEmploymentsList]: state => ({
        ...state,
        ids:[]
    }),

    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getEmployments             = (state, entities) => denormalize(state.ids, employmentList, entities);
export const isEmployeesFetching        = state => state.fetching;
export const isDeleteUsersEmployment    = state => state.isDeleteUsersEmployment;
export const getTotalUsersForDealers      = state => state.count;