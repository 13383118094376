import { combineReducers } from 'redux';
import { localizeReducer as localize } from "react-localize-redux";
import { reducer as form } from 'redux-form'

import entities from './entities';
import menu, * as fromMenu from './menu';
import modix, * as fromModix from './modix';
import authentication, * as fromAuth from "./authentication";
import signin, * as fromSignin from "./signin";
import signup, * as fromSignup from "./signup";
import forgotPassword, * as fromForgotPassword from "./forgotPassword";
import recoverPassword, * as fromRecoverPassword from "./recoverPassword";
import users, * as fromUsers from "./users";
import employments, * as fromEmployments from "./employments";
import dealers, * as fromDealers from "./dealers";
import invitations, * as fromInvitations from "./invitations";
import profile, * as fromProfile from "./profile";
import account from "./account";
import emailConfirm, * as fromEmailConfirm from "./emailConfirm";
import inquiries, * as fromInquiries from './inquiries';
import stats, * as fromStats from './stats';
import orders, * as fromOrders from './orders';
import locales, * as fromLocales from './locales';
import countries, * as fromCountries from './countries';
import products, * as fromProducts from "./products";
import cart, * as fromCart from "./cart";
import codeweavers, * as fromCodeweavers from "./codeweavers";
import partExchange, * as fromPartExchange from "./partExchange";


export default combineReducers({
	modix,
	menu,
	form,
	localize,
	entities,
	authentication,
	signin,
	signup,
	users,
	orders,
	products,
	dealers,
	invitations,
	forgotPassword,
	recoverPassword,
	profile,
	account,
	emailConfirm,
	employments,
	inquiries,
	countries,
	locales,
	stats,
	cart,
	codeweavers,
	partExchange
});

//form
export const getSpecificFormDetails = (state, formName) => state.form[formName] ? state.form[formName] : state.form.global;

// Authentication
export const getUser = state => fromAuth.getUser(state.authentication, state.entities);
export const getToken = state => fromAuth.getToken(state.authentication);
export const getRefreshToken = state => fromAuth.getRefreshToken(state.authentication);
export const getGoogleSocialObject = state => fromAuth.getGoogleSocialObject(state.authentication);
export const getSelectedDealer = (state) => fromAuth.getSelectedDealer(state.authentication, state.entities);
export const isGranted = (state, attribute, subject = null) => fromAuth.isGranted(state.authentication, state.entities, attribute, subject);

// Modix
export const getApp = state => fromModix.getApp(state.modix, state.entities);
export const getSnack = state => fromModix.getSnack(state.modix);
export const getLocale = state => fromModix.getLocale(state.modix);
export const getRedirect = state => fromModix.getRedirect(state.modix);
export const getDealerForTheme = state => fromModix.getDealerForTheme(state.modix);
export const getShouldHideHeader = state => fromModix.getShouldHideHeader(state.modix);

// Signin
export const isLoginFetching = state => fromSignin.isLoginFetching(state.signin);
export const isGoogleLoginFetching = state => fromSignin.isGoogleLoginFetching(state.signin);

// Signup
export const isSignupInviteFetching = state => fromSignup.isSignupInviteFetching(state.signup);
export const isSignupFetching = state => fromSignup.isSignupFetching(state.signup);

// Forgot Password
export const isForgotPasswordFetching = state => fromForgotPassword.isForgotPasswordFetching(state.forgotPassword);
export const isForgotPasswordFoundAccount = state => fromForgotPassword.getFoundAccount(state.forgotPassword);
export const isLostPasswordFetching = state => fromRecoverPassword.isLostPasswordFetching(state.forgotPassword);
export const isRecoverPasswordFetching = state => fromRecoverPassword.isRecoverPasswordFetching(state.forgotPassword);

// Localize
export const getLanguages = state => state.localize.languages;
export const getLocalize = state => state.localize;

// Users
export const getUsers = state => fromUsers.getUsers(state.users, state.entities);
export const isUsersFetching = state => fromUsers.isUsersFetching(state.users);
export const getUserProfile = (state, userId) => fromUsers.getUserProfile(state.users, state.entities, userId);
export const isUsersEditFetching = state => fromUsers.isUsersEditFetching(state.users);
export const isUsersDeleteFetching = state => fromUsers.isUsersDeleteFetching(state.users);
export const isFetchingProfile = state => fromUsers.isFetchingProfile(state.users);
export const getTotalUsers = state => fromUsers.getTotalUsers(state.users);

// Employments
export const getEmployments             = state => fromEmployments.getEmployments(state.employments, state.entities);
export const isDeleteUsersEmployment    = state => fromEmployments.isDeleteUsersEmployment(state.employments);
export const isEmployeesFetching    = state => fromEmployments.isEmployeesFetching(state.employments);
export const getTotalUsersForDealers = state => fromEmployments.getTotalUsersForDealers(state.employments);

// Dealers Page
export const getDealers = state => fromDealers.getDealers(state.dealers, state.entities);
export const isDealerFetching = state => fromDealers.isDealerFetching(state.dealers);
export const getDealerById      = (state, dealerId) => fromDealers.getDealerById(state.dealers, state.entities, dealerId);
export const getDealerOpeningHoursById = (state, dealerId) => fromDealers.getDealerOpeningHoursById(state.dealers, state.entities, dealerId);
export const isDealerCreateFetching = state => fromDealers.isDealerCreateFetching(state.dealers);
export const isEditDealerFetching = state => fromDealers.isEditDealerFetching(state.dealers);
export const isDealerDeleteFetching = state => fromDealers.isDealerDeleteFetching(state.dealers);
export const isUserDealerDetachFetching = state => fromDealers.isUserDealerDetachFetching(state.dealers);
export const isUserDealerAttachFetching = state => fromDealers.isUserDealerAttachFetching(state.dealers);
export const isUserDealerAttachFetchingPublic = state => fromDealers.isUserDealerAttachFetchingPublic(state.dealers);
export const isModulesConfigFetching =      state => fromDealers.isModulesConfigFetching(state.dealers);
export const getTotalDealers = state => fromDealers.getTotalDealers(state.dealers);
export const isLogoUploading = state => fromDealers.isLogoUploading(state.dealers);
export const isThemeConfigUpdating = state => fromDealers.isThemeConfigUpdating(state.dealers);

// Dealer settings
export const isFetchingDealer = state => fromDealers.isFetchingDealer(state.dealers);
export const isFetchingUploadDocuments = state => fromDealers.isFetchingUploadDocuments(state.dealers);
export const isResetThemeConfigUpdating = state => fromDealers.isResetThemeConfigUpdating(state.dealers);
export const isDownloadingDealerDocument = state => fromDealers.isDownloadingDealerDocument(state.dealers);

// Dealer Invitations
export const isDealerInviteCreateFetching = state => fromInvitations.isDealerInviteCreateFetching(state.invitations);
export const isDealerViewInvitesFetching = state => fromInvitations.isDealerViewInvitesFetching(state.invitations);
export const getDealerInvites = state => fromInvitations.getDealerInvites(state.invitations, state.entities);
export const isFetchingDeleteDealerInvite = state => fromInvitations.isFetchingDeleteDealerInvite(state.invitations);
export const getTotalCountInvites = state => fromInvitations.getTotalCountInvites(state.invitations);

// Edit Profile
export const isEditProfileFetching = state => fromProfile.isEditProfileFetching(state.profile);
export const isFetchingUploadImage = state => fromProfile.isFetchingUploadImage(state.profile);
export const isFetchingSocial = state => fromProfile.isFetchingSocial(state.profile);
export const isFetchingEmailVerify = state => fromProfile.isFetchingEmailVerify(state.profile);

// Email Confirm
export const isEmailConfirmFetching = state => fromEmailConfirm.isEmailConfirmFetching(state.emailConfirm);

// Menu
export const getMenuItems = state => fromMenu.getMenuItems(state.menu, state.entities);
export const isMenuItemsFetching = state => fromMenu.isMenuItemsFetching(state.menu);
export const getActiveMenuItemId = state => fromMenu.getActiveMenuItemId(state.menu);
export const getMobileOpen = state => fromMenu.getMobileOpen(state.menu);

// Inquiries
export const getInquiries = (state) => fromInquiries.getInquiries(state.inquiries, state.entities);
export const getTotalInquiries = state => fromInquiries.getTotalInquiries(state.inquiries);
export const getTotalCountByTestDrive = (state) => fromInquiries.getTotalCountByTestDrive(state.inquiries);
export const getTotalCountByFinanceOrLease = (state) => fromInquiries.getTotalCountByFinanceOrLease(state.inquiries);
export const isFetchingResendConfirmation = (state) => fromInquiries.isFetchingResendConfirmation(state.inquiries);
export const isFetchingCancelInquiry = (state) => fromInquiries.isFetchingCancelInquiry(state.inquiries);
export const inquiryCancelled = (state) => fromInquiries.inquiryCancelled(state.inquiries);
export const isFetchingInquiry = (state) => fromInquiries.isFetchingInquiry(state.inquiries);
export const getStatusSubmitting  = (state) => fromInquiries.getStatusSubmitting(state.inquiries);
export const isFetchingAssignInquiry = (state) => fromInquiries.isFetchingAssignInquiry(state.inquiries);
export const isFetchingInquiries = (state) => fromInquiries.isFetchingInquiries(state.inquiries);
export const isInquiriesEditFetching = (state) => fromInquiries.isInquiriesEditFetching(state.inquiries);
export const isInquiryLogsFetching = (state) => fromInquiries.isInquiryLogsFetching(state.inquiries);
//..
export const getInquiry = (id, state) => fromInquiries.getInquiry(id, state.entities);
export const isSubmittingInquiryReply = (state) => fromInquiries.isSubmittingInquiryReply(state.inquiries);
export const getInquiryMessages = (state) => fromInquiries.getInquiryMessages(state.inquiryMessages, state.inquiries);
export const isFetchingInquiryMessages = (state) => fromInquiries.isFetchingInquiryMessages(state.inquiries);

export const isInquiriesUpdateTimeslotFetching = (state) => fromInquiries.isInquiriesUpdateTimeslotFetching(state.inquiries);
export const isVehicleBlockedTimeslotsFetching = (state) => fromInquiries.IsVehicleBlockedTimeslotsFetching(state.inquiries);
export const getBlockedTimeSlots = state => fromInquiries.getBlockedTimeSlots(state.inquiries, state.entities);

// Stats
export const isDealerStatsFetching = state => fromStats.isDealerStatsFetching(state.stats);
export const isReportFetching = state => fromStats.isReportFetching(state.stats);
export const getDealerStatsInterval = state => fromStats.getDealerStatsInterval(state.stats);
export const getDealerStats = state => fromStats.getDealerStats(state.stats);

//Orders
export const getOrders = state => fromOrders.getOrders(state.orders, state.entities);
export const getOrderIds = state => fromOrders.getOrderIds(state.orders);
export const getTotalOrders = state => fromOrders.getTotalOrders(state.orders);
export const getOrdersPage = state => fromOrders.getOrdersPage(state.orders);
export const getNumFetchedOrders = state => fromOrders.getNumFetchedOrders(state.orders);
export const isOrdersFetching = state => fromOrders.isOrdersFetching(state.orders);
export const isConsumerOrderEditFetching = (state) => fromOrders.isConsumerOrderEditFetching(state.orders);
export const getOrder = (id, state) => fromOrders.getOrder(id, state.entities);
export const isStatusOrderFetching = state => fromOrders.isStatusOrderFetching(state.orders);
export const isCancelOrderFetching = state => fromOrders.isCancelOrderFetching(state.orders);
export const isOrderLogsFetching = state => fromOrders.isOrderLogsFetching(state.orders);
export const isFetchingOrderMessages = state => fromOrders.isFetchingOrderMessages(state.orders);
export const isSubmittingOrderReply = state => fromOrders.isSubmittingOrderReply(state.orders);
export const isOrderTimeSoltUpdating = state => fromOrders.isOrderTimeSoltUpdating(state.orders);
export const isOrderSubmitting = state => fromOrders.isOrderSubmitting(state.orders);
export const isOrderSubmittingTermsAndConditions = state => fromOrders.isOrderSubmittingTermsAndConditions(state.orders);
export const isOrderSubmittingType = state => fromOrders.isOrderSubmittingType(state.orders);
export const isOrderCreateStripePaymentSubmitting = state => fromOrders.isOrderCreateStripePaymentSubmitting(state.orders);
export const isOrderCreatePaypalPaymentSubmitting = state => fromOrders.isOrderCreatePaypalPaymentSubmitting(state.orders);
export const getFetchingVerifyPayment = state => fromOrders.getFetchingVerifyPayment(state.orders);
export const getFetchingVerifyFinance = state => fromOrders.getFetchingVerifyFinance(state.orders);

export const getDocusignEnvelope = (id, state) => fromOrders.getDocusignEnvelope(id, state.entities);
export const isDocusignEnvelopeRequesting = state => fromOrders.isDocusignEnvelopeRequesting(state.orders);
export const isDocusignEnvelopeUpdating = state => fromOrders.isDocusignEnvelopeUpdating(state.orders);
export const isDocusignEnvelopeFetching = state => fromOrders.isDocusignEnvelopeFetching(state.orders);
export const isDocusignEnvelopeDownloading = state => fromOrders.isDocusignEnvelopeDownloading(state.orders);
export const isSingleOrderFetching = state => fromOrders.isSingleOrderFetching(state.orders);


//Countries
export const getCountries = state => fromCountries.getCountries(state.countries, state.entities);
export const isFetchingCountries = state => fromCountries.isFetchingCountries(state.countries);

// Products
export const getProducts = state => fromProducts.getProducts(state.products, state.entities);
export const isProductsFetching = state => fromProducts.isProductsFetching(state.products);
export const getSingleProduct = (state, productId) => fromProducts.getSingleProduct(state.products, state.entities, productId);
export const getTotalProducts = state => fromProducts.getTotalProducts(state.products);

// Cart
export const getProductIdsFromCart = state => fromCart.getProductIdsFromCart(state.cart);
export const getQuoteReference = state => fromCart.getQuoteReference(state.cart);
export const getVehicleProductFromCart = state => fromCart.getVehicleProductFromCart(state, state.cart);

// locales
export const getLocales = state => fromLocales.getLocales(state.locales, state.entities);
export const getAllLocales = state => fromLocales.getAllLocales(state.locales, state.entities);
export const isFetchingLocale = state => fromLocales.isFetchingLocale(state.locales);
export const isUpdatingLocale = state => fromLocales.isUpdatingLocale(state.locales);

// Codeweavers
export const getFetchingCodeweaversFinance = state => fromCodeweavers.getFetchingCodeweaversFinance(state.codeweavers);
export const isFinanceDetailsVisible = state => fromCodeweavers.isFinanceDetailsVisible(state.codeweavers);

// Part Exchange
export const isSubmittingPartExchange = state => fromPartExchange.isSubmittingPartExchange(state.partExchange);
