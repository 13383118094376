import { withStyles } from "@material-ui/core";
import React from "react";

const styles = theme => ({
    logo: {
        width: '100%',
        minHeight: '60px'
    }
});

const Logo = ({
    classes,
    logoType,
    theme
}) => (
    <img 
        alt="Logo"
        className={classes.logo}
        src={theme.logos[logoType] ? theme.logos[logoType] : theme.logos.default}
    />
);

export default withStyles(styles, {withTheme: true})(Logo);
