import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Avatar, Grid, Typography} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
const UserName = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
`;

const UserEmail = styled(Typography)`
  color: ${props => props.theme.sidebar.color};
  font-size: 12px;
`;

const UserInfoWrapper = styled.div`
  padding: ${props => props.theme.spacing(3)}px ${props => props.theme.spacing(4)}px;
`;

let UserInfo = ({
    user,
}) => (
    !user ? "" : <UserInfoWrapper>
        <Grid container spacing={2}>
            <Grid item>
                {user?.avatar ?
                    <Avatar
                        alt={user.firstname + " " + user.lastname}
                        src={user?.avatar?.url}
                    /> : <Avatar
                        alt={user.firstname + " " + user.lastname}
                    >
                        <PersonIcon />
                    </Avatar>}
            </Grid>
            <Grid item>
                <UserName variant="body2">
                    {user.firstname} {user.lastname}
                </UserName>
                <UserEmail variant="body2">
                    {user.email}
                </UserEmail>
            </Grid>
        </Grid>
    </UserInfoWrapper>
);

UserInfo.propTypes = {
    user: PropTypes.object.isRequired,
};

export default UserInfo;