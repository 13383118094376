import createRemoteAction from '../createRemoteAction'
import {dealerItem, localeItemList} from "../schema";
import {createAction, handleActions} from "redux-actions";
import {denormalize} from "normalizr";
import {getLocale} from "./root";
import {setLocale} from "./modix";

const setLocaleFilterInternal = createAction('SET_LOCALE_FILTER');
export const setLocaleFilter = locales => (dispatch, getState) => {
    const selectedLocale = getLocale(getState())
    dispatch(setLocaleFilterInternal(locales));
    if (locales && !locales.includes(selectedLocale)) {
        dispatch(setLocale(locales[0]));
    }
};

export const locales = createRemoteAction({
    type: 'LOCALES',
    url: '/api/configs/locales',
    headers: {
        'Authorization': undefined
    },
    schema:localeItemList
});

export const updateDealerLocales = createRemoteAction({
    type: 'UPDATE_DEALERS_LOCALES',
    url: props => `/api/dealers/${props.data.id}/locales`,
    method: 'PUT',
    schema: dealerItem
});

const initialState = {
    ids: [],
    localeFilter: null,
    fetching: false,
    updating: false,
    error: {},
};

export default handleActions({
    [setLocaleFilterInternal]: (state, action) => ({
        ...state,
        localeFilter: action.payload,
    }),
    [locales.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [locales.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
    }),

    [updateDealerLocales.begin]: state => ({
        ...state,
        updating: true,
    }),
    [updateDealerLocales.request]: state => ({
        ...state,
        updating: false,
    }),

    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getAllLocales = (state, entities) => {
    let locales = denormalize(state.ids, localeItemList, entities);
    if (!locales || !locales.length)
        return {};

    const list = {};
    locales.forEach(locale => {
        list[locale.id] = locale.language;
    });

    return list;
}

export const getLocales = (state, entities)  => {
    const locales = getAllLocales(state, entities);
    if ('object' === typeof state.localeFilter && Array.isArray(state.localeFilter)) {
        return Object.keys(locales)
            .filter(localeKey => state.localeFilter.includes(localeKey))
            .reduce((result, key) => {
                result[key] = locales[key];
                return result;
            }, {});
    }

    return locales;

};

export const isFetchingLocale = state => state.fetching;
export const isUpdatingLocale = state => state.updating;
