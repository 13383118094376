import createRemoteAction from "../createRemoteAction";
import {
    dealerInvitesList,
    dealerInvitesItem,
} from "../schema";
import {handleActions} from "redux-actions";
import {denormalize} from "normalizr";
import bqs from '../buildQueryString';


export const createDealerInvite = createRemoteAction({
    type: 'CREATE_Dealer_INVITE',
    method: 'POST',
    url: '/api/invitations'
});

export const viewDealerInvites = createRemoteAction({
    type: "VIEW_Dealer_INVITES",
    method: "GET",
    url: props => "/api/dealers/" + props.dealerId + "/invitations?" + bqs(props),
    schema: dealerInvitesList
});

export const deleteDealerInvite = createRemoteAction({
    type: "DELETE_DEALER_INVITE",
    method: "DELETE",
    url: props => `/api/invitations/${props.data.id}`,
    schema: dealerInvitesItem
});

const initialState = {
    fetchingInviteCreate: false,
    fetchingViewDealerInvites: false,
    dealerInvitesIds: [],
    fetchingDeleteDealerInvite: false,
    error: null,
    countInvites : 0,
};

export default handleActions({
    [createDealerInvite.begin]: state => ({
        ...state,
        fetchingInviteCreate: true,
    }),
    [createDealerInvite.request]: state => ({
        ...state,
        fetchingInviteCreate: false
    }),
    [viewDealerInvites.begin]: state => ({
        ...state,
        fetchingViewDealerInvites: true,
    }),
    [viewDealerInvites.request]: (state, action) => ({
        ...state,
        fetchingViewDealerInvites: false,
        error: action.error,
        dealerInvitesIds: action.error ? state.dealerInvitesIds : action.payload.normalized.result,
        countInvites: action.error ? 0 : action.payload.count,
    }),
    [deleteDealerInvite.begin]: state => ({
        ...state,
        fetchingDeleteDealerInvite: true
    }),
    [deleteDealerInvite.request]: deleteDealerInvite.deleteReducer('fetchingDeleteDealerInvite', 'dealerInvitesIds'),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const isDealerInviteCreateFetching = state => state.fetchingInviteCreate;
export const isDealerViewInvitesFetching = state => state.fetchingViewDealerInvites;
export const getDealerInvites = (state, entities) => denormalize(state.dealerInvitesIds, dealerInvitesList, entities);
export const isFetchingDeleteDealerInvite = state => state.fetchingDeleteDealerInvite;
export const getTotalCountInvites = state => state.countInvites;
