import createRemoteAction from '../createRemoteAction'
import {countryItemList} from "../schema";
import {handleActions} from "redux-actions";
import {denormalize} from "normalizr";


export const countries = createRemoteAction({
 type: 'COUNTRIES',
 url: '/api/countries',
 schema: countryItemList,
 headers: {
     'Authorization': undefined
 }
});

const initialState = {
    ids: [],
    fetching: false,
    error: {},
};

export default handleActions({
    [countries.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [countries.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
    }),

    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getCountries = (state, entities)  => {
    let countries = denormalize(state.ids, countryItemList, entities);
    if(countries.length) {
        let list = {};
        countries.map(country => {
             list[country.countryCode] = country.countryName;
         });
         return list;
    }
    return countries;
};
export const isFetchingCountries = state => state.fetching;
