import { createMuiTheme } from "@material-ui/core/styles";

import {defaultThemeColorConfig, defaultThemeLogoConfig} from "./defaultThemeConfig";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";
import {green, grey, red} from "@material-ui/core/colors";
import {logoTypes, themeProperties} from "../config";

const getColor = (config, propertyName) => {
    let colourInStorage = localStorage.getItem(propertyName);
    return config && config[propertyName] ?
        config[propertyName] :
        (colourInStorage && isColor(colourInStorage) ? colourInStorage : defaultThemeColorConfig[propertyName]);
};

const isColor = (strColor) => /^#[0-9A-F]{6}$/i.test(strColor);

const resetThemeLocalStorage = () => {
    themeProperties.forEach(colorsProperties => {
        localStorage.removeItem(colorsProperties);
    });

    logoTypes.forEach(logoProperty => {
        localStorage.removeItem(logoProperty);
    });
};

const getVariants = (dealerOrConsumer) => {
    if (dealerOrConsumer) {

        resetThemeLocalStorage();

        themeProperties.forEach(colorsProperty => {
            if (dealerOrConsumer.themeColorConfig && dealerOrConsumer.themeColorConfig[colorsProperty])
                localStorage.setItem(colorsProperty, dealerOrConsumer.themeColorConfig[colorsProperty]);
        });

        logoTypes.forEach(logoProperty => {
            if (dealerOrConsumer[logoProperty]?.url)
                localStorage.setItem(logoProperty, dealerOrConsumer[logoProperty]?.url);
        });

    }

    let primaryColor = getColor(dealerOrConsumer?.themeColorConfig , 'primaryColor');
    let secondaryColor = getColor(dealerOrConsumer?.themeColorConfig , 'secondaryColor');
    let primaryContrastTextColor = getColor(dealerOrConsumer?.themeColorConfig , 'primaryContrastTextColor');
    let secondaryContrastTextColor = getColor(dealerOrConsumer?.themeColorConfig , 'secondaryContrastTextColor');
    let headerText = getColor(dealerOrConsumer?.themeColorConfig , 'headerTextColor');
    let headerBackground = getColor(dealerOrConsumer?.themeColorConfig , 'headerBackgroundColor');
    let sidebarBackground = getColor(dealerOrConsumer?.themeColorConfig , 'sidebarBackgroundColor');
    let sidebarHeaderText = getColor(dealerOrConsumer?.themeColorConfig , 'sidebarHeaderTextColor');
    let sidebarHeaderBackground = getColor(dealerOrConsumer?.themeColorConfig , 'sidebarHeaderBackgroundColor');
    let body = getColor(dealerOrConsumer?.themeColorConfig , 'bodyBackgroundColor');

    let defaultLogo = dealerOrConsumer?.defaultLogo?.url ??
        (null !== localStorage.getItem('defaultLogo') ?
            localStorage.getItem('defaultLogo') :
            defaultThemeLogoConfig.logos.default);

    let headerLogo = dealerOrConsumer?.headerLogo?.url ??
        (null !== localStorage.getItem('headerLogo') ?
            localStorage.getItem('headerLogo') :
            null);

    let lightBackgroundLogo = dealerOrConsumer?.lightBackgroundLogo?.url ??
        (null !== localStorage.getItem('lightBackgroundLogo') ?
            localStorage.getItem('lightBackgroundLogo') :
            defaultThemeLogoConfig.logos.lightBackground);

    let variants = {
        logos: {
            default: defaultLogo,
            header: headerLogo,
            lightBackground: lightBackgroundLogo
        },
        palette: {
            primary: {
                main: primaryColor,
                contrastText: primaryContrastTextColor
            },
            secondary: {
                main: secondaryColor,
                contrastText: secondaryContrastTextColor
            }
        },
        header: {
            color: headerText,
            background: headerBackground,
            search: {
                color: grey[100]
            },
            indicator: {
                background: red[700]
            }
        },
        sidebar: {
            color: grey[900],
            background: sidebarBackground,
            header: {
                color: sidebarHeaderText,
                background: sidebarHeaderBackground,
                brand: {
                    color: "#FFFFFF"
                }
            },
            footer: {
                color: grey[900],
                background: grey[100],
                online: {
                    background: green[500]
                }
            },
            badge: {
                color: "#FFF",
                background: green[600]
            }
        },
        body: {
            background: body
        }
    };

    if (dealerOrConsumer?.themeColorConfig?.showDealerNameAtHeader === true) {
        variants.name = dealerOrConsumer.name ?? dealerOrConsumer.dealerName;
    }

    return variants;
};

const theme = (dealerOrConsumer) => {
    let variant = getVariants(dealerOrConsumer);
    return createMuiTheme(
        {
          spacing: 4,
          breakpoints: breakpoints,
          overrides: overrides,
          props: props,
          typography: typography,
          shadows: shadows,
          body: variant.body,
          header: variant.header,
          palette: variant.palette,
          sidebar: variant.sidebar,
          logos: variant.logos,
          name: variant.name,
        },
        variant.name
    );
};

export default theme;
