import UserMenu from '../../components/Layout/UserMenu';
import {connect} from "react-redux";
import {logout} from "../../state/authentication";
import {getLocale} from "../../state/root";



const mapStateToProps = state => ({
    locale: getLocale(state),
});

const mapDispatchToProps = dispatch => ({
    onClickLogout: (locale) => {
        const storageList = [
            'token',
            'refresh_token',
            'google_social_object'
        ];
        storageList.forEach((storageName) => {
            localStorage.removeItem(storageName);
        });

        dispatch(logout());
        window.location.href = `${process.env.REACT_APP_LOGIN_URL}/${locale}`;
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
