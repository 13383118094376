import createRemoteAction from '../createRemoteAction';
import {userItem, userList} from "../schema";
import {handleActions} from "redux-actions";
import {denormalize} from "normalizr";
import bqs from '../buildQueryString';

export const users = createRemoteAction({
    type: 'USERS',
    url: ({ ...params}) => `/api/users?${bqs(params)}`,
    schema: userList,
});

export const getUser = createRemoteAction({
    type: 'USERS_SEARCH',
    url: params => `/api/users/search?${bqs(params)}`,
    // mapping the users for use in autocomplete
    map: userItem => ({
            iri: userItem['@id'],
            id: userItem.id,
            label: userItem.firstname + " " + userItem.lastname,
            info: userItem.email,
            abbreviation: userItem.firstname[0] + "" + userItem.lastname[0],
            avatar: userItem.avatar,
            dealers: userItem.employments.map(employment => employment.dealer.id),
            originalEntity: userItem,
        })
});

export const editUsers = createRemoteAction({
    type: 'EDIT_USERS',
    method: 'PUT',
    url: props => `/api/users/${props.data.id}`,
    schema: userItem,
});

export const deleteUser = createRemoteAction({
    type: 'DELETE_USER',
    method: 'DELETE',
    url: props => `/api/users/${props.data.id}`,
});

export const userProfile = createRemoteAction({
    type: 'USER_PROFILE',
    method: 'GET',
    url: props => props.userId === props.authUserId ? `/api/users/me` : `/api/users/${props.userId}`,
    schema: userItem,
});

export const userEmailVerify = createRemoteAction({
    type: 'USER_EMAIL_VERIFY',
    method: 'PUT',
    url: props => `/api/users/${props.context.userId}/email-verify`,
});

const initialState = {
    ids: [],
    fetching: false,
    fetchingEdit: false,
    fetchingDelete: false,
    isFetchingProfile: false,
    isUpdatingEmployment:false,
    isDeleteUsersEmployment:false,
    error: null,
    count: 0,
};

export default handleActions({
    [users.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [users.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
        count: action.error ? 0 : action.payload.count
    }),
    [editUsers.begin]: state => ({
        ...state,
        fetchingEdit: true,
    }),
    [editUsers.request]: state => ({
        ...state,
        fetchingEdit: false,
    }),
    [deleteUser.begin]: state => ({
        ...state,
        fetchingDelete: true,
    }),
    [deleteUser.request]: deleteUser.deleteReducer('fetchingDelete'),

    [userProfile.begin]: state => ({
        ...state,
        isFetchingProfile: true,
    }),
    [userProfile.request]: state => ({
        ...state,
        isFetchingProfile: false,
    }),

    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getUsers                   = (state, entities) => denormalize(state.ids, userList, entities);
export const getUserProfile             = (state, entities, userId) => denormalize(userId, userItem, entities);
export const isUsersFetching            = state => state.fetching;
export const isUsersEditFetching        = state => state.fetchingEdit;
export const isUsersDeleteFetching      = state => state.fetchingDelete;
export const isFetchingProfile          = state => state.isFetchingProfile;
export const getTotalUsers              = state => state.count;