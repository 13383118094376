import createRemoteAction from '../createRemoteAction'
import {handleActions} from "redux-actions";

export const lostPassword = createRemoteAction({
    type: "LOST_PASSWORD_GET",
    method: "GET",
    url: props => `/api/lost-passwords/${props.data.lostPasswordToken}`,
    headers: {
        'Authorization': undefined
    }
});

export const recoverPassword = createRemoteAction({
    type: "RCOVER_PASSWORD",
    method: "PUT",
    url: props => `/api/users/${props.context.userId}/password`,
    headers: {
        'Authorization': undefined
    }
});

const initialState = {
    fetchingLostPasswordToken: false,
    fetchingRecoverPassword: false
};

export default handleActions({
    [lostPassword.begin]: state => ({
        ...state,
        fetchingLostPasswordToken: true
    }),
    [lostPassword.request]: state => ({
        ...state,
        fetchingLostPasswordToken: false
    }),
    [recoverPassword.begin]: state => ({
        ...state,
        fetchingRecoverPassword: true
    }),
    [recoverPassword.request]: state => ({
        ...state,
        fetchingRecoverPassword: false
    }),
}, initialState);

export const isLostPasswordFetching = state => state.fetchingLostPasswordToken;
export const isRecoverPasswordFetching = state => state.fetchingRecoverPassword;
