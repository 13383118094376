import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import Sidebar from "./Sidebar";
import Header from "./Header";

import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const drawerWidth = 280;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${props => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const Paper = styled(MuiPaper)`
  padding: ${props => props.theme.spacing(5)}px;
  ${props => props.theme.breakpoints.down("xs")} {
    padding: ${props => props.theme.spacing(2)}px;
  }
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};
  width: 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

class Protected extends React.Component {

  render() {
    const { children, routes, width, setMobileOpen, mobileOpen } = this.props;

    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Hidden mdUp implementation="js">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={() => setMobileOpen(false)}
            />
          </Hidden>
          <Hidden smDown implementation="css">
            <Sidebar
              routes={routes}
              PaperProps={{ style: { width: drawerWidth } }}
            />
          </Hidden>
        </Drawer>
        <AppContent>
          <Header onDrawerToggle={() => setMobileOpen(!mobileOpen)} />
          <MainContent p={isWidthUp("lg", width) ? 10 : 8}>
            {children}
          </MainContent>
        </AppContent>
      </Root>
    );
  }
}

export default withWidth()(Protected);
