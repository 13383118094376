import {createAction, handleActions} from 'redux-actions';
import {denormalize} from "normalizr";
import {dealerItem, userItem} from "../schema";
import {addSnack} from "./modix";
import axios from "axios";
import {menu} from "./menu";
import {removeEmploymentsList} from "./employments";
import {allDealers} from "../containers/General/DealerSelect";
import {setLocaleFilter} from "./locales";
import {getDealerById} from "./root";


export const setUser = createAction('AUTH_SET_USER');

const logoutInternal = createAction('AUTH_LOGOUT');
export const logout = () => dispatch => {

    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('selected_dealer');
    localStorage.removeItem('google_social_object');
    localStorage.removeItem('selected_dealer');

    dispatch(logoutInternal());
};

export const setToken = createAction('AUTH_SET_TOKEN');
export const setRefreshToken = createAction('AUTH_SET_REFRESH_TOKEN');
export const setGoogleSocialObject = createAction('AUTH_SET_GOOGLE_SOCIAL_OBJECT');
const selectDealerInternal = createAction('AUTH_SELECT_DEALER');
export const selectDealer = dealer => (dispatch, getState) => {
    if (null !== dealer && 'object' === typeof dealer && 'number' === typeof dealer.id) {
        // get full dealer object from state, because this method may be called
        // with simple dealer object from localstorage that only contains the dealer id
        const fullDealer = getDealerById(getState(), dealer.id) ?? dealer;
        if (null !== fullDealer && 'object' === typeof fullDealer) {
            localStorage.setItem('selected_dealer', JSON.stringify({id: fullDealer.id}));
            if (dealer.id === 0)
                delete axios.defaults.headers.common['X-Dealer']
            else
                axios.defaults.headers.common['X-Dealer'] = dealer.id;

            dispatch(setLocaleFilter(fullDealer.locales));
            dispatch(selectDealerInternal(fullDealer));
        }

    } else {
        delete axios.defaults.headers.common['X-Dealer'];
        localStorage.removeItem('selected_dealer');
        dispatch(setLocaleFilter(null));
    }

    dispatch(removeEmploymentsList());
    dispatch(menu.action()).catch(() => {
        dispatch(addSnack({variant: "error", message: "Could not load menu"}));
    });
};

const initialState = {
    token: null,
    refreshToken: null,
    googleSocialObject: null,
    userId: null,
    selectedDealerId: null,
};

export default handleActions({
    [setUser]: (state, action) => ({
        ...state,
        userId: action.payload.normalized.result,
    }),
    [setToken]: (state, action) => ({
        ...state,
        token: action.payload,
    }),
    [setRefreshToken]: (state, action) => ({
        ...state,
        refreshToken: action.payload,
    }),
    [setGoogleSocialObject]: (state, action) => ({
        ...state,
        googleSocialObject: action.payload
    }),
    [selectDealerInternal]: (state, action) => ({
        ...state,
        selectedDealerId: action.payload.id,
    }),
    [logout]: () => ({
        ...initialState,
    }),
}, initialState);

export const getUser = (state, entities) => denormalize(state.userId, userItem, entities);
export const getToken = state => state.token;
export const getRefreshToken = state => state.refreshToken;
export const getGoogleSocialObject = state => state.googleSocialObject;
export const getSelectedDealer = (state, entities) => {

    // 0 = all dealers for admin role with additional dealeradmin role
    if (0 === state.selectedDealerId) {
        return allDealers;
    }

    if (state.selectedDealerId) {
        return denormalize(state.selectedDealerId, dealerItem, entities);
    }

    const user = getUser(state, entities);
    if (!user)
        return null;

    const employments = user.employments;
    if (employments.length)
        return employments[0].dealer;

    return null;
};

export const getSelectedEmployment = (state, entities) => {

    const user = getUser(state, entities);

    if (!user)
        return null;

    if (state.selectedDealerId) {

        for (let index in user.employments) {
            if (user.employments[index].dealer.id === state.selectedDealerId){
                return user.employments[index];
            }
        }
    }

    return user.employments[0];
};

export const isGranted = (state, entities, attribute, subject = null) => {
    const user = getUser(state, entities);

    if (user?.roles?.includes(attribute))
        return true;

    const employment = getSelectedEmployment(state, entities);

    if (employment?.roles?.includes(attribute))
        return true;

    if ("ROLE_DEALER" === attribute) {
        return employment?.roles?.includes("ROLE_DEALERADMIN");
    }

    return false;
};
