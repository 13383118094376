import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import styled from "styled-components";
import localization from '../../localization';
import { setLocale } from "../../state/modix";
import {
    getLocale,
    getLocales, getUser,
} from "../../state/root";
import {editProfile} from "../../state/profile";

const LangButton = styled(IconButton)({
    textTransform: 'uppercase',
    fontSize: 18,
    width: 48,
    height: 48,
});

class LanguageMenu extends React.Component
{
    state = {
        anchorMenu: null,
    };

    toggleMenu = event => {
        this.setState({ anchorMenu: event.currentTarget });
    };

    closeMenu = locale => {
        const {setActiveLanguage, setLocale, user} = this.props;
        if (locale) {
            setActiveLanguage(locale);
            setLocale(locale, user);
        }

        this.setState({ anchorMenu: null });
    };

    render() {
        const { anchorMenu } = this.state;
        const { localeSelected , locales} = this.props;
        const open = Boolean(anchorMenu);
        return (
            <React.Fragment>
                <LangButton
                    aria-owns={open ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={this.toggleMenu}
                    color="inherit"
                >
                    {localeSelected.substr(0,2)}
                </LangButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorMenu}
                    open={open}
                    onClose={() => this.closeMenu()}
                >
                    {Object.keys(locales).map((locale, key) => (
                        <MenuItem key={key}
                                  onClick={() => this.closeMenu(locale)}
                                  selected={locale === localeSelected}
                        >
                            {localization.locales[locale]}
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setLocale: (locale, user) => {
        dispatch(setLocale(locale));
        user && dispatch(editProfile.action({data: {
            id: user.id,
            locale
        }}));
    },
});


const mapStateToProps = state => ({
    localeSelected: getLocale(state),
    locales: getLocales(state),
    user: getUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(LanguageMenu));
