import React from 'react';
import PropTypes from 'prop-types';
import {Power} from "react-feather";
import {IconButton} from "@material-ui/core";

const UserMenu = ({
    onClickLogout,
    locale
}) => (
    <React.Fragment>
        <IconButton
            color="inherit"
            onClick={() => onClickLogout(locale)}
        >
            <Power />
        </IconButton>
    </React.Fragment>
);

UserMenu.propTypes = {
    onClickLogout: PropTypes.func.isRequired,
};

export default UserMenu;