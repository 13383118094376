import React from "react";
import styled, { withTheme } from "styled-components";
import LanguageMenu from "../General/LanguageMenu";

import {
  Grid,
  AppBar as MuiAppBar,
  Toolbar,
  Typography
} from "@material-ui/core";

const AppBar = styled(MuiAppBar)`
  background: ${props => props.theme.header.background};
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const HeaderTitle = styled(Typography)`
  margin-left: ${props => props.theme.spacing(2)}px;
`;

const Brand = styled(Grid)`
  display: flex;
  align-items: center;
`;


const PublicHeader = ({theme}) => {

  return (
      <React.Fragment>
        <AppBar position="sticky" elevation={0}>
          <Toolbar>
            <Grid container alignItems="center">
              <Brand item>
                {theme?.logos?.header && <img src={theme.logos.header} height="42" />}
                {theme.name && <HeaderTitle variant="h4">{theme.name}</HeaderTitle>}
              </Brand>
              <Grid item xs/>
              <Grid item>
                <LanguageMenu/>
              </Grid>

            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
  );
};



export default (withTheme(PublicHeader));
