import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import Header from "./Header";
import { spacing } from "@material-ui/system";
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};
  width: 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

class ProtectedWithoutSidebar extends React.Component {
    render() {
    const { children, routes, width } = this.props;

    return (
        <Root>
          <CssBaseline />
          <GlobalStyle />
          <AppContent>
            <Header />
            <MainContent p={isWidthUp("lg", width) ? 10 : 8}>
              {children}
            </MainContent>
          </AppContent>
        </Root>
    );
  }
}

export default withWidth()(ProtectedWithoutSidebar);
