import {createAction, handleActions} from "redux-actions";
import {getSingleProduct} from "./root";

export const setQuoteReference = createAction('SET_QUOTE_REFERENCE');
export const addProductToCart = createAction("ADD_PRODUCT_TO_CART");
export const clearCart = createAction("CART_CLEAR");

const initialState = {
    productIds: [],
    quoteReference: null
};

export default handleActions({
    [addProductToCart]: (state, action) => ({
        ...state,
        productIds: [...state.productIds, action.payload]
    }),
    [setQuoteReference]: (state, action) => ({
        ...state,
        quoteReference: action.payload,
    }),
    [clearCart]: (state) => ({
        ...state,
        productIds: []
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getProductIdsFromCart = state => state.productIds;
export const getVehicleProductFromCart = (globalState , state) => state.productIds ? getSingleProduct(globalState, state.productIds[0]) : null;
export const getQuoteReference = state => state.quoteReference;

