import React from "react";
import DealerSelect from "../../components/General/DealerSelect";
import {connect} from "react-redux";
import {getSelectedDealer, getUser, isGranted} from "../../state/root";
import {selectDealer} from "../../state/authentication";
import {Translate} from "react-localize-redux";

export const allDealers = {
    id: 0,
    name: <Translate id="digitalRetail.all_dealers" />,
};

const mapStateToProps = state => {

    const user = getUser(state);
    const dealers = user?.employments?.map(employment => employment.dealer);
    const selected = getSelectedDealer(state);

    if (isGranted(state, 'ROLE_ADMIN', user)) {
        dealers.unshift(allDealers);
    }

    return {
        selected,
        dealers,
    };
};

const mapDispatchToProps = dispatch => ({
    handleSelect: dealer => dispatch(selectDealer(dealer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DealerSelect);