import createRemoteAction from '../createRemoteAction';
import {productList, productItem} from "../schema";
import {handleActions} from "redux-actions";
import {denormalize} from "normalizr";
import bqs from '../buildQueryString';

export const products = createRemoteAction({
    type: 'GET_PRODUCTS',
    url: ({stockProviderKey, stockDealerId, stockVehicleId}) => `/api/products/${stockProviderKey}/${stockDealerId}/${stockVehicleId}`,
    schema: productItem,
    headers: {
        'Authorization': undefined
    }
});

const initialState = {
    ids: [],
    fetching: false,
    error: null,
    count: 0,
};

export default handleActions({
    [products.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [products.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
        count: action.error ? 0 : action.payload.count
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getProducts                = (state, entities) => denormalize(state.ids, productList, entities);
export const getSingleProduct           = (state, entities, productId) => denormalize(productId, productItem, entities);
export const isProductsFetching         = state => state.fetching;
export const getTotalProducts           = state => state.count;
