import createRemoteAction from '../createRemoteAction';
import {order, orderList, docusignEnvelopeItem, paymentItem} from "../schema";
import {createAction, handleActions} from "redux-actions";
import {denormalize, normalize} from "normalizr";
import qs from "qs";

export const orders = createRemoteAction({
    type: 'ORDERS',
    url: ({dealer, ...params}) =>  '/api/dealers/'+ dealer.id + '/orders?'+ qs.stringify(params, {arrayFormat: 'bracket', encode: false, skipNulls: true}),
    schema: orderList,
});

export const fetchOrder = createRemoteAction({
    type: 'GET_ORDER',
    method: 'GET',
    url: props => `/api/orders/${props.id}`,
    schema: order,
});

export const consumerOrders = createRemoteAction({
    type: 'CONSUMER_ORDERS',
    url: ({consumer, ...params}) => `/api/consumers/${consumer.id}/orders?${qs.stringify(params, {arrayFormat: 'bracket', encode: false, skipNulls: true})}`,
    schema: orderList
});

export const consumerDetailsUpdate = createRemoteAction({
    type: 'UPDATE_CONSUMER_ORDER_DETAILS',
    method: 'PUT',
    url: props => `/api/orders/${props.context.orderId}`,
    schema: order,
});

export const statusOrder = createRemoteAction({
    type: 'STATUS_ORDER',
    method: 'PUT',
    url: props => `/api/orders/${props.order.id}/${props.action}`,
    schema: order
});

export const cancelOrder = createRemoteAction({
    type: 'CANCEL_ORDER',
    method: 'PUT',
    url: props => `/api/orders/${props.context.order.id}/cancel`,
    schema: order
});

export const orderLogs = createRemoteAction({
    type: 'ORDER_LOGS',
    method: 'GET',
    url: props => `/api/logs?objectClass=App%5CEntity%5COrder&objectId=${props.id}`
});

export const getSingleDocusignEnvelope = createRemoteAction({
    type: 'GET_ORDER_DOCUSIGN_ENVELOPE',
    method: 'GET',
    url: props => `/api/docusign-envelopes/${props.id}`,
    schema: docusignEnvelopeItem
});

export const downloadDocusignEnvelope = createRemoteAction({
    type: 'DOWNLOAD_DOCUSIGN_ENEVELOPE',
    method: 'GET',
    responseType:'blob',
    url: props => `/api/docusign-envelopes/${props.context.id}/download`,
});

export const createDocusignEnvelope = createRemoteAction({
    type: 'ORDER_DOCUSIGN_ENVELOPE',
    method: 'POST',
    url: props => `/api/docusign-envelopes`
});

export const updateDocusignEnvelope = createRemoteAction({
    type: 'ORDER_DOCUSIGN_ENVELOPE_UPDATE',
    method: 'PUT',
    url: props => `/api/docusign-envelopes/${props.id}`,
    schema: docusignEnvelopeItem
});

export const refreshDocusignEnvelopeUrl = createRemoteAction({
    type: 'REFRESH_DOCUSIGN_ENVELOPE_URL',
    method: 'PUT',
    url: props => `/api/docusign-envelopes/${props.id}/refresh-url`,
    schema: docusignEnvelopeItem
});

export const orderReply = createRemoteAction({
    type: 'ORDER_REPLY',
    method: 'POST',
    url: props => '/api/order-messages',
    extraStateManagement: response => {
        if (response?.data) {
            let orderId = response.props.context.orderId;
            let orderObj = {
                id: orderId,
                messages: [response.data]
            };
            return normalize(orderObj, order);
        }
    },
    forceArrayPush: true,
    forceArrayPushForField: 'messages'
});

export const orderMessages = createRemoteAction({
    type: 'ORDER_MESSAGES',
    method: 'GET',
    url: props => `/api/orders/${props.orderId}/messages?pagination=false`,
    extraStateManagement: response => {
        if (response?.data) {
            if (response?.data) {
                let orderId = response.props.orderId;
                let orderObj = {
                    id: orderId,
                    messages: response.data
                };
                return normalize(orderObj, order);
            }
        }
    }
});

export const orderUpdateTimeSlot = createRemoteAction({
    type: 'ORDER_UPDATE_TIMESLOT',
    method: 'PUT',
    url: props => `/api/orders/${props.context.orderId}/delivery-date`,
    schema: order,
});

export const orderCreate = createRemoteAction({
    type: "ORDER_INITIATED",
    method: "POST",
    url: params => "/api/orders",
    schema: order
});

export const orderTermsAndConditions = createRemoteAction({
    type: "ORDER_TERMS_AND_CONDITIONS",
    method: "PUT",
    url: params => `/api/orders/${params.context.orderId}/terms-and-conditions`,
    schema: order
});

export const orderType = createRemoteAction({
    type: "ORDER_TYPE",
    method: "PUT",
    url: params => `/api/orders/${params.context.orderId}/type`,
    schema: order
});

export const orderCreateStripePaymentIntent = createRemoteAction({
    type: 'ORDER_CREATE_STRIPE_PAYMENT_INTENT',
    method: 'POST',
    url: props => `/api/payments/stripe/intent`
});

export const orderCreateStripePaymentSession = createRemoteAction({
    type: 'ORDER_CREATE_STRIPE_PAYMENT_SESSION',
    method: 'POST',
    url: props => `/api/payments/stripe/initiate`
});

export const orderCreatePaypalPayment = createRemoteAction({
    type: 'ORDER_CREATE_PAYPAL_PAYMENT',
    method: 'POST',
    url: props => `/api/payments/paypal/initiate`,
});

export const verifyOrderPayment = createRemoteAction({
    type: 'ORDER_VERIFY_PAYMENT',
    method: 'PUT',
    url: props => `/api/payments/${props.id}/verify`,
    schema: paymentItem,
});

export const verifyOrderFinance = createRemoteAction({
    type: 'ORDER_VERIFY_FINANCE_APPLICATION',
    method: 'PUT',
    url: props => `/api/finances/${props.id}/verify`,
    schema: paymentItem,
});

export const orderUpdateStripePaymentIntent = createRemoteAction({
    type: 'ORDER_UPDATE_STRIPE_PAYMENT_INTENT',
    method: 'PUT',
    url: props => `/api/payments/stripe/intent/${props?.context?.id}`,
    extraStateManagement: response => {
        if (response?.data?.order && 'object' === typeof response.data.order) {
            return normalize(response.data.order, order);
        }
    }
});

export const setOrdersPage = createAction('ORDER_SET_PAGE');

const initialState = {
    ids: [],
    fetchingSingleOrder: false,
    fetching: false,
    fetchingLogs: false,
    fetchingStatus: false,
    fetchingCancel: false,
    fetchingEdit: false,
    submitting: false,
    updating: false,
    fetchingMessages: false,
    fetchingVerifyPayment: false,
    fetchingVerifyFinance: false,
    submittingStripePaymentSession: false,
    submittingPaypalPayment: false,
    submittingReply: false,
    submittingCreate: false,
    submittingTermsAndConditions: false,
    submittingType: false,
    docusignEnvelopeSubmitting: false,
    docusignEnvelopeUpdating: false,
    docusignEnvelopeFetching: false,
    docusignEnvelopeDownloading: false,
    error: null,
    count: 0,
    page: 1,
};

export default handleActions({
    [orders.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [orders.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : action.payload.normalized.result,
        count: action.error ? 0 : action.payload.count
    }),
    [fetchOrder.begin]: state => ({
        ...state,
        fetchingSingleOrder: true,
    }),
    [fetchOrder.request]: state => ({
        ...state,
        fetchingSingleOrder: false,
    }),
    [consumerOrders.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [consumerOrders.request]: (state, action) => ({
        ...state,
        fetching: false,
        error: action.error,
        ids: action.error ? state.ids : [...new Set([...state.ids ,...action.payload.normalized.result])],
        count: action.error ?  state.count : action.payload.count
    }),
    [statusOrder.begin]: state => ({
        ...state,
        fetchingStatus: true
    }),
    [statusOrder.request]: (state, action) => ({
        ...state,
        fetchingStatus: false
    }),
    [cancelOrder.begin]: state => ({
        ...state,
        fetchingCancel: true
    }),
    [cancelOrder.request]: (state, action) => ({
        ...state,
        fetchingCancel: false
    }),
    [orderLogs.begin]: state => ({
        ...state,
        fetchingLogs: true,
    }),
    [orderLogs.request]: state => ({
        ...state,
        fetchingLogs: false,
    }),
    [orderMessages.begin]: state => ({
        ...state,
        fetchingMessages: true,
    }),
    [orderMessages.request]: state => ({
        ...state,
        fetchingMessages: false,
    }),
    [orderReply.begin]: state => ({
        ...state,
        submittingReply: true,
    }),
    [orderReply.request]: state => ({
        ...state,
        submittingReply: false,
    }),
    [orderUpdateTimeSlot.begin]: state => ({
        ...state,
        updating: true,
    }),
    [orderUpdateTimeSlot.request]: state => ({
        ...state,
        updating: false,
    }),
    [consumerDetailsUpdate.begin]: state => ({
        ...state,
        submitting: true,
    }),
    [consumerDetailsUpdate.request]: state => ({
        ...state,
        submitting: false,
    }),
    [orderCreate.begin]: state => ({
        ...state,
        submittingCreate: true,
    }),
    [orderCreate.request]: state => ({
        ...state,
        submittingCreate: false,
    }),
    [orderTermsAndConditions.begin]: state => ({
        ...state,
        submittingTermsAndConditions: true,
    }),
    [orderTermsAndConditions.request]: state => ({
        ...state,
        submittingTermsAndConditions: false,
    }),
    [orderType.begin]: state => ({
        ...state,
        submittingType: true,
    }),
    [orderType.request]: state => ({
        ...state,
        submittingType: false,
    }),
    [createDocusignEnvelope.begin]: state => ({
        ...state,
        docusignEnvelopeRequesting: true,
    }),
    [createDocusignEnvelope.request]: (state, action) => ({
        ...state,
        docusignEnvelopeRequesting: false,
    }),
    [refreshDocusignEnvelopeUrl.begin]: state => ({
        ...state,
        docusignEnvelopeRequesting: true,
    }),
    [refreshDocusignEnvelopeUrl.request]: (state, action) => ({
        ...state,
        docusignEnvelopeRequesting: false,
    }),
    [updateDocusignEnvelope.begin]: state => ({
        ...state,
        docusignEnvelopeUpdating: true
    }),
    [updateDocusignEnvelope.request]: state => ({
        ...state,
        docusignEnvelopeUpdating: false
    }),
    [orderCreateStripePaymentSession.begin]: state => ({
        ...state,
        submittingStripePaymentSession: true
    }),
    [orderCreateStripePaymentSession.request]: state => ({
        ...state,
        submittingStripePaymentSession: false
    }),
    [orderCreatePaypalPayment.begin]: state => ({
        ...state,
        submittingPaypalPayment: true
    }),
    [orderCreatePaypalPayment.request]: state => ({
        ...state,
        submittingPaypalPayment: false
    }),
    [getSingleDocusignEnvelope.begin]: state => ({
        ...state,
        docusignEnvelopeFetching: true
    }),
    [getSingleDocusignEnvelope.request]: state => ({
        ...state,
        docusignEnvelopeFetching: false
    }),
    [downloadDocusignEnvelope.begin]: state => ({
        ...state,
        docusignEnvelopeDownloading: true
    }),
    [downloadDocusignEnvelope.request]: state => ({
        ...state,
        docusignEnvelopeDownloading: false
    }),
    [verifyOrderPayment.begin]: state => ({
        ...state,
        fetchingVerifyPayment: true
    }),
    [verifyOrderPayment.request]: state => ({
        ...state,
        fetchingVerifyPayment: false
    }),
    [setOrdersPage]: (state, action) => ({
       ...state,
       page: action.payload,
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getOrders                   = (state, entities) => denormalize(state.ids, orderList, entities);
export const getOrderIds                 = state => state.ids;
export const getOrder                    = (id, entities) => denormalize(id, order, entities);
export const getDocusignEnvelope         = (id, entities) => denormalize(id, docusignEnvelopeItem, entities);
export const getTotalOrders              = state => state.count;
export const getOrdersPage              = state => state.page;
export const getNumFetchedOrders         = state => state.ids.length;
export const isOrdersFetching            = state => state.fetching;
export const isFetchingOrderMessages     = state => state.fetchingMessages;
export const isSubmittingOrderReply      = state => state.submittingReply;
export const isStatusOrderFetching       = state => state.fetchingStatus;
export const isCancelOrderFetching       = state => state.fetchingCancel;
export const isOrderLogsFetching         = state => state.fetchingLogs;
export const isOrderTimeSoltUpdating     = state => state.updating;
export const isDocusignEnvelopeRequesting= state => state.docusignEnvelopeRequesting;
export const isDocusignEnvelopeUpdating  = state => state.docusignEnvelopeUpdating;
export const isDocusignEnvelopeFetching  = state => state.docusignEnvelopeFetching;
export const isDocusignEnvelopeDownloading  = state => state.docusignEnvelopeDownloading;

export const isConsumerOrderEditFetching = state => state.submitting;
export const isOrderSubmitting           = state => state.submittingCreate;
export const isOrderSubmittingTermsAndConditions = state => state.submittingTermsAndConditions;
export const isOrderSubmittingType = state => state.submittingType;

export const isOrderCreateStripePaymentSubmitting = state => state.submittingStripePaymentSession;
export const isOrderCreatePaypalPaymentSubmitting = state => state.submittingPaypalPayment;
export const getFetchingVerifyPayment = state => state.fetchingVerifyPayment;
export const getFetchingVerifyFinance = state => state.fetchingVerifyFinance;
export const isSingleOrderFetching = state => state.fetchingSingleOrder;
