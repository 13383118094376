import _merge from 'lodash.merge'
import objectPath from 'object-path';
import Dealer from "../entities/Dealer";

/**
 * Removes all properties on the original object
 * which are an array AND also exist on the target object.
 * this allows to use lodash.merge to "delete" array items.
 *
 * @param obj
 * @param path
 * @param level
 * @param original
 */
const prepareForArrayMerge = (obj, path = "", level = 0, original, forceArrayPush, forceArrayPushForField, currentField = "") => {
  if (path && Array.isArray(objectPath.get(original, path)) && (null == obj || Array.isArray(obj))) {
      if (!(forceArrayPush && (forceArrayPushForField === currentField))) {
          objectPath.del(original, path);
      }
      else {//array push case
          let originalArray = objectPath.get(original, path);
          originalArray = originalArray.concat(obj);
          objectPath.set(original, path, originalArray);
          objectPath.del(obj, "0");
      }
  }

  if (null === obj || undefined === obj || typeof obj !== 'object')
      return;

    for (let prop of Object.keys(obj)) {
        if (obj.hasOwnProperty(prop)) {
            prepareForArrayMerge(obj[prop], path ? `${path}.${prop}` : prop, level + 1, original, forceArrayPush, forceArrayPushForField, prop);
        }
    }
};

const prepareForReplaceProperties = (action, newState) => {
    if (!!action.payload?.config?.replaceProperties) {
        let replacePoperties = action.payload.config.replaceProperties;
        let result = action.payload.normalized.result;
        let resultType = typeof result;
        if ("object" !== resultType || !Array.isArray(result)) {
            result = [result];
        }

        result.forEach((id) => {
            replacePoperties.forEach(property => {
                objectPath.del(newState, [property.schemaKey, id, property.name])
            });
        });
    }
}

// receives normalized entities and merges them into state
export default (
    state = {},
    action
) => {
    let obj;
    const newState = {...state};

    if (action.normalized)
        obj = action.normalized.entities;
    else if (action.payload && action.payload.normalized)
        obj = action.payload.normalized.entities;

    if (obj) {
        prepareForArrayMerge(obj, null, 0, newState, !!action.payload?.config?.forceArrayPush, action.payload?.config?.forceArrayPushForField, "");
        prepareForReplaceProperties(action, newState);
        return _merge(newState, obj);
    }

    if (action.type === 'AUTH_LOGOUT') {
        return {};
    }

    return newState;
}
