import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import Header from "./PublicHeader";
import { spacing } from "@material-ui/system";
import {
  CssBaseline,
  Paper as MuiPaper,
  withWidth
} from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import {connect} from "react-redux";
import {getShouldHideHeader} from "../../state/root";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const Paper = styled(MuiPaper)`
  padding: ${props => props.theme.spacing(5)}px;
  ${props => props.theme.breakpoints.down("xs")} {
    padding: ${props => props.theme.spacing(2)}px;
  }
`;

const MainContent = styled(Paper)`
  flex: 1;
  background: ${props => props.theme.body.background};
  width: 100%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

class PublicLayoutWithHeader extends React.Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { children, width, hideHeader } = this.props;

    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />

        <AppContent>
          { /* TODO: le ugliest, disgustig and shitty-nitty loukaniko hack */
            !hideHeader &&
            <Header onDrawerToggle={this.handleDrawerToggle} />}
          <MainContent p={isWidthUp("lg", width) ? 10 : 8}>
            {children}
          </MainContent>
        </AppContent>
      </Root>
    );
  }
}


const mapStateToProps = state => ({
  hideHeader: getShouldHideHeader(state)
});


export default connect(mapStateToProps)(withWidth()(PublicLayoutWithHeader));
