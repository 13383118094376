import createRemoteAction from '../createRemoteAction'
import {handleActions} from "redux-actions";
import {userItem} from "../schema";

export const editAccount = createRemoteAction({
    type: "EDIT_ACCOUNT_CREDENTIALS",
    method: "PUT",
    url: props => {
        if (props && props.data && props.data.password && props.data.password_repeat && props.data.id) {
            return `/api/users/${props.data.id}/credentials`
        }
    },
    schema: userItem
});

export const removeSocialAccount = createRemoteAction({
    type: "REMOVE_SOCIAL_ACCOUNT",
    method: "DELETE",
    url: props => `/api/social/account/${props.data.provider}`,
    schema: userItem
});

const initialState = {
    isFetchingAccount: false,
    isFetchingSocial: false
};

export default handleActions({
    [editAccount.begin]: state => ({
        ...state,
        isFetchingAccount: true,
    }),
    [editAccount.request]: state => ({
        ...state,
        isFetchingAccount: false
    }),
    [removeSocialAccount.begin]: state => ({
        ...state,
        isFetchingSocial: true
    }),
    [removeSocialAccount.request]: state => ({
        ...state,
        isFetchingSocial: false
    }),
}, initialState);

export const isEditAccountFetching = state => state.isFetchingAccount;
export const isFetchingSocial = state => state.isFetchingSocial;