import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import {Select, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
        padding: theme.spacing(1),
    },
    select: {
        color: theme.palette.primary.contrastText,
    },
});

const DealerSelect = ({
    classes,
    selected,
    dealers,
    handleSelect,
}) => {
    if (typeof dealers === 'undefined' || dealers.length < 2) return null;
    return (
        <div className={classes.root}>
            <Select
                className={classes.select}
                value={selected.id}
                onChange={e => handleSelect(dealers.find(dealer => e.target.value === dealer.id))}
            >
                {dealers && Object.values(dealers).map(dealer => (
                    <MenuItem className={classes.item} key={dealer.id} value={dealer.id}>{dealer.name}</MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default withStyles(styles)(DealerSelect);
