import createRemoteAction from '../createRemoteAction';
import { handleActions } from 'redux-actions';

const initialState = {
  fetchingPartExchange: false,
  submittingPartExchange: false,
};

export const createPartExchange = createRemoteAction({
  method: 'POST',
  url: () => '/api/order-items',
  type: 'CREATE_PART_EXCHANGE',
})

export default handleActions({
  [createPartExchange.begin]: state => ({
    ...state,
    submittingPartExchange: true,
  }),
  [createPartExchange.request]: state => ({
    ...state,
    submittingPartExchange: false,
  })
}, initialState);

export const isSubmittingPartExchange       = state => state.submittingPartExchange;
export const isFetchingPartExchange         = state => state.fetchingPartExchange;
