import axios from 'axios'
import {getRefreshToken, getToken, getUser, getGoogleSocialObject, getLocale} from "./state/root";
import {normalize} from "normalizr";
import {userItem} from "./schema";
import {logout, setRefreshToken, setToken, setUser, setGoogleSocialObject} from "./state/authentication";
import qs from 'querystring';
import {addSnack} from "./state/modix";
import {setGoogleLoginFetching} from "./state/signin";

let getState, dispatch;
let lastToken, lastLocale;

/**
 * Configure the API
 */
export const configure = (getStateMethod, dispatchMethod, frontendURL) => {
    getState = getStateMethod;
    dispatch = dispatchMethod;
    axios.defaults.baseURL = process.env.REACT_APP_API_URL +'/';
    axios.defaults.headers.common['Accept'] = 'application/ld+json';
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';
    axios.defaults.headers.common['Pragma'] = 'no-cache'; // HTTP 1.0 compat
    axios.defaults.headers.common['Accept-Language'] = 'en-GB';
    axios.defaults.headers.common['X-Frontend-Url'] = frontendURL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.interceptors.response.use(onAxiosResponse, onAxiosError);
};

export const setTokens = (token, refreshToken, returnPromise) => {

    localStorage.setItem('token', token);
    dispatch(setToken(token));

    localStorage.setItem('refresh_token', refreshToken);
    dispatch(setRefreshToken(refreshToken));

    if (!!returnPromise)
        return Promise.resolve({message: "Tokens set successfully."});
};

export const setGoogleObject = obj => {
    assignSocialObject('google', obj ? {
        token: obj.token,
        refreshToken: obj.refreshToken,
    } : null);
};

const assignSocialObject = (name, obj) => {
    if (null === obj)
        localStorage.removeItem(`${name}_social_object`);
    else
        localStorage.setItem(`${name}_social_object`, JSON.stringify(obj));
    dispatch(setGoogleSocialObject(obj));
};

/**
 * Refresh the authentication token using the refresh-token
 * @returns {Promise}
 */
const refreshToken = () => {
    if (!getState) return;
    const token = getRefreshToken(getState());
    if (!token) {
        return Promise.reject({message: "Login abgelaufen. Bitte melde dich erneut an."});
    }

    return new Promise((resolve, reject) => {
        return axios.post('/api/login/refresh', {refresh_token: token
        }, {'headers': {'X-NoRefreshToken': '1'}}).then(response => {
            setTokens(response.data.token, response.data.refresh_token);
            resolve(response);

        }).catch(error => {
            reject(error);
        });
    });
};

const refreshGoogleToken = () => {
    if (!getState) return;
    const googleSocialObject = getGoogleSocialObject(getState());
    if (googleSocialObject && googleSocialObject.refreshToken) {
        return new Promise((resolve, reject) => {
            axios.post('/api/social-tokens/refresh', {
                provider: 'Google',
                refreshToken: googleSocialObject.refreshToken,
            },{
                headers: {'Authorization': undefined},
            }).then((tokenResponse) => {
                setGoogleObject(tokenResponse.data);
                resolve(tokenResponse.data);
            }).catch((error) => {
                setGoogleObject(null);
                reject(error);
            })
        });
    }

    return Promise.reject({message: 'No google social object'});
};

export const getGoogleRefreshToken = data => {
    dispatch(setGoogleLoginFetching(true));
    data.provider = 'Google';
    // more info: https://github.com/anthonyjgrove/react-google-login/issues/249#issuecomment-648521245
    data.redirectUri = 'postmessage'; // it's a must for response type "code" and "offline" access type
    return new Promise((resolve, reject) => {
        axios.post('/api/social-tokens/code', data, {
            headers: {'Authorization': undefined}
        }).then((tokenResponse) => {
            dispatch(setGoogleLoginFetching(false));
            setGoogleObject(tokenResponse.data);
            resolve();
        }).catch((error) => {
            setGoogleObject(null);
            dispatch(setGoogleLoginFetching(false));
            reject(error);
        });
    });
};


/**
 * Global axios onResponse callback
 * @param config
 * @returns {Promise}
 */
const onAxiosResponse = (config) => {
    return Promise.resolve(config);
};

/**
 * Global axios error callback.
 *
 * If we receive a 401 Unauthorized response, try to
 * authenticate again using the refresh-token.
 *
 * @param error
 * @returns {Promise<never>}
 */

const onRefreshError = () => {
    dispatch(logout());
    window.location.href = process.env.REACT_APP_LOGIN_URL;
};

const onAxiosError = async (error) => {
    if (error?.response?.status === 401 && !error?.config?.headers['X-NoRefreshToken']) {
        let originalRequest = error.config;
        const state = getState();
        const modixRefreshToken = getRefreshToken(state);
        if (modixRefreshToken) {
            const response = await refreshToken().catch(onRefreshError);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.data.token;
            const {data} = await axios(originalRequest);
            originalRequest.data = data;
            return Promise.resolve(originalRequest);
        }

        const googleObject = getGoogleSocialObject(state);
        if (googleObject && googleObject.refreshToken) {
            const googleTokens = await refreshGoogleToken().catch(onRefreshError);
            originalRequest.headers['Authorization'] = `Google ${googleTokens.token}`;
            const {data} = await axios(originalRequest);
            originalRequest.data = data;
            return Promise.resolve(originalRequest);
        }
    }

    return Promise.reject(error);
};

const updateAxiosHeaders = (state) => {
    const token = getToken(state);
    if (lastToken !== token) {
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        } else {
            axios.defaults.headers.common['Authorization'] = undefined;
        }

        lastToken = token;
    }

    const googleSocialObj = getGoogleSocialObject(state);
    if (googleSocialObj && Object.keys({googleSocialObj}).length) {
        axios.defaults.headers.common['Authorization'] = `Google ${googleSocialObj.token}`;
    }
};

const updateLocale = (state) => {
    const locale = getLocale(state);
    if (lastLocale !== locale) {
        axios.defaults.headers.common['Accept-Language'] = locale.replace(/_/g, '-');
        lastLocale = locale.replace(/-/g, '_');
    }
};

export const onStateChange = () => {
    if (!getState) return;
    const state = getState();
    updateAxiosHeaders(state);
    updateLocale(state);
};


const receiveUser = user => {
    const normalized = normalize(user, userItem);
    dispatch(setUser({normalized}));
    return Promise.resolve(getUser(getState()));
};

export const checkForErrorInUrl = (propsLocation) => {
    let queryParamasObj = propsLocation && propsLocation.search ? qs.parse(decodeURI(propsLocation.search).substr(1)) : {};
    let errorStr = queryParamasObj.hasOwnProperty('error') ? queryParamasObj.error : null;

    if (errorStr) {
        dispatch(addSnack({
            variant: "error",
            message: errorStr,
            duration: 8000
        }));
    }
};

const hasLoginTokens = (state) => {
    return getToken(state) || getGoogleSocialObject(state);
};

export const checkLogin = async (customHeader) => {
    if (!getState) return Promise.reject({message: 'Internal error: getState() is not configured.'});
    if (!dispatch) return Promise.reject({message: 'Internal error: dispatch() is not configured.'});
    if (!hasLoginTokens(getState())) return Promise.reject({message: 'No automatic login because no tokens were found.'});

    try {
        let config = {};
        if ("undefined" !== typeof customHeader) {
             config = {headers: customHeader}
        }
        const response = await axios.get('/api/users/me', config);
        return receiveUser(response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};
