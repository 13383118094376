import createRemoteAction from '../createRemoteAction'
import {handleActions} from "redux-actions";

export const signupInvite = createRemoteAction({
    type: 'SIGNUP_INVITE',
    method: "POST",
    url: props => `/api/invitations/${props.data.invitationToken}`
});

export const signup = createRemoteAction({
    type: "SIGNUP",
    method: "POST",
    headers: {
        Authorization: undefined,
    },
    url: '/api/users'
});

const initialState = {
    fetching: false,
};

export default handleActions({
    [signupInvite.begin]: state => ({
        ...state,
        fetching: true
    }),
    [signupInvite.request]: state => ({
        ...state,
        fetching: false
    }),
    [signup.begin]: state => ({
        ...state,
        fetching: true
    }),
    [signup.request]: state => ({
        ...state,
        fetching: false
    }),
}, initialState);

export const isSignupInviteFetching = state => state.fetching;
export const isSignupFetching = state => state.fetching;
