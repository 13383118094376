import React from "react";
import styled from "styled-components";

import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: calc(50vh + 10px);
  left: calc(50vw - 10px);
`;

function Loader({color}) {
  return (
    <Root>
      <CircularProgress m={2} color={color ?? "secondary"} />
    </Root>
  );
}

export default Loader;
