import createRemoteAction from '../createRemoteAction'
import {handleActions} from "redux-actions";

export const forgotPassword = createRemoteAction({
    type: 'FORGOT_PASSWORD',
    method: "POST",
    url: '/api/lost-passwords',
    headers: {
        'Authorization': undefined
    }
});

const initialState = {
    fetchingForgotPassword: false,
    foundAccount: false
};

export default handleActions({
    [forgotPassword.begin]: state => ({
        ...state,
        fetchingForgotPassword: true,
        foundAccount: false
    }),
    [forgotPassword.request]: (state, action) => ({
        ...state,
        fetchingForgotPassword: false,
        foundAccount: (action.payload.status && action.payload.status === 201) ? true : false
    }),
}, initialState);

export const isForgotPasswordFetching = state => state.fetchingForgotPassword;
export const getFoundAccount = state => state.foundAccount;
