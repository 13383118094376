import createRemoteAction from '../createRemoteAction'
import {handleActions, createAction} from "redux-actions";
import {mediaObjectItem, userItem, consumerItem} from "../schema";

export const editProfile = createRemoteAction({
    type: "EDIT_PROFILE_DATA",
    method: "PUT",
    url: props => {
        return `/api/users/${props.data.id}`
    },
    schema: userItem,
});

export const editConsumerProfile = createRemoteAction({
    type: "EDIT_CONSUMER_DATA",
    method: "PUT",
    url: props => `/api/consumers/${props.context.id}`,
    schema: consumerItem
});

export const editProfileEmail = createRemoteAction({
    type: "EDIT_PROFILE_EMAIL",
    method: "POST",
    url: `/api/email-change-requests`,
});

export const editProfilePassword = createRemoteAction({
    type: "EDIT_PROFILE_PASSWORD",
    method: "PUT",
    url: props => `/api/users/${props.context.userId}/password`,
    schema: userItem,
});

export const resendEmailVerify = createRemoteAction({
    type: "RESEND_EMAIL_VERIFY",
    method: "PUT",
    url: props => `/api/users/${props.user.id}/resend-email-verify`,
    schema: userItem,
});

export const replaceUser = createAction("REPLACE_USER");

export const UserAccountProvider = createRemoteAction({
    type: "ADD_SOCIAL_ACCOUNT",
    method: "POST",
    url:  `/api/user_accounts`,
});

export const removeSocialAccount = createRemoteAction({
    type    : "REMOVE_SOCIAL_ACCOUNT",
    method  : "DELETE",
    url     : props => `/api/user_accounts/${props.data.provider}`,
});

export const uploadImage = createRemoteAction({
    type: "UPLOAD_PROFILE_IMAGE",
    method: "POST",
    headers: {
        "Content-Type": 'multipart/form-data'
    },
    url: () => `/api/media-objects`,
    schema: mediaObjectItem,
});

export const deleteImage = createRemoteAction({
    type: "DELETE_PROFILE_IMAGE",
    method: "DELETE",
    url: props => `/api/media-objects/${props.id}`,
    schema: mediaObjectItem,
});

const initialState = {
    fetching: false,
    isFetchingUploadImage: false,
    isFetchingSocial: false,
    isFetchingConfirmation:false
};

export default handleActions({
    [editProfile.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [editProfile.request]: (state, action) => ({
        ...state,
        fetching: false,
    }),
    [editConsumerProfile.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [editConsumerProfile.request]: (state, action) => ({
        ...state,
        fetching: false,
    }),
    [editProfilePassword.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [editProfilePassword.request]: state => ({
        ...state,
        fetching: false,
    }),
    [editProfileEmail.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [editProfileEmail.request]: state => ({
        ...state,
        fetching: false,
    }),
    [removeSocialAccount.begin]: state => ({
        ...state,
        fetching: true
    }),
    [removeSocialAccount.request]: state => ({
        ...state,
        fetching: false
    }),
    [uploadImage.begin]: state => ({
        ...state,
        isFetchingUploadImage: true,
    }),
    [uploadImage.request]: state => ({
        ...state,
        isFetchingUploadImage: false,
    }),
    [deleteImage.begin]: state => ({
        ...state,
        isFetchingUploadImage: true,
    }),
    [deleteImage.request]: state => ({
        ...state,
        isFetchingUploadImage: false,
    }),
    [resendEmailVerify.begin]: state => ({
        ...state,
        resendEmailVerify: true,
    }),
    [resendEmailVerify.request]: state => ({
        ...state,
        resendEmailVerify: false,
    }),
}, initialState);

export const isEditProfileFetching = state => state.fetching;
export const isFetchingSocial = state => state.isFetchingSocial;
export const isFetchingUploadImage = state => state.isFetchingUploadImage;
export const isFetchingEmailVerify = state => state.resendEmailVerify;
