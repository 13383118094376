export default (type, schema, entity, unvisit) => {

    // part #1: this piece is the original denormalize code from normalizr
    Object.keys(schema).forEach(key => {
        if (entity.hasOwnProperty(key)) {
            entity[key] = unvisit(entity[key], schema[key]);
        }
    });

    // part #2: create typed entiy with methods etc.
    const typedEntity = new type;
    typedEntity.fromObject(entity);

    return typedEntity;
}
