import React from "react";
import PropTypes from 'prop-types';
import {ThemeProvider} from "styled-components";
import Routes from "../containers/Routes";
import Loading from "./General/Loading";
import {StylesProvider} from "@material-ui/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MuiThemeProvider} from "@material-ui/core/styles";
import maTheme from "../theme";
import SnackBar from "./Layout/SnackBar";

let App = ({
    user,
    isPublic,
    selectedDealer,
    dealerForTheme
}) => {
    let content;
    if (user || isPublic) {
        content = <Routes/>;
    } else {
        content = <Loading/>;
    }

    let dealerOrConsumer = selectedDealer || dealerForTheme || user?.consumer;
    let selectedTheme = maTheme(dealerOrConsumer);

    return (
        <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={selectedTheme}>
                    <ThemeProvider theme={selectedTheme}>
                        <React.Fragment>
                            {content}
                            <SnackBar />
                        </React.Fragment>
                    </ThemeProvider>
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </StylesProvider>
    );
};

App.propTypes = {
    user: PropTypes.object,
    theme: PropTypes.string,
    isPublic: PropTypes.bool,
};

export default App;