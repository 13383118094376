import createRemoteAction from '../createRemoteAction';
import {createAction, handleActions} from "redux-actions";
import {order as orderSchema} from "../schema";
import {normalize} from "normalizr";

export const showFinanceDetailsOnVehicleCard = createAction('SHOW_FINANCE_DETAILS_ON_VEHICLE_CARD');

export const initiateCodeWeaversFinance = createRemoteAction({
    type: 'INITIATE_CODEWEAVERS_FINANCES',
    method: 'POST',
    url: () => '/api/finances/codeweavers/initiate',
    extraStateManagement: (response) => {
        if (response?.data) {
            let orderId = response.data.order.split('/').slice(-1).pop();
            let order = {
                id: orderId,
                payments: [response.data]
            };
            return normalize(order, orderSchema);
        }
    },
    forceArrayPush: true,
    forceArrayPushForField: 'payments'
});

const initialState = {
    fetching: false,
    showFinanceDetailsOnVehicleCard: true
};

export default handleActions({
    [showFinanceDetailsOnVehicleCard]: (state, action) => ({
        ...state,
        showFinanceDetailsOnVehicleCard: action.payload.visible
    }),
    [initiateCodeWeaversFinance.begin]: state => ({
        ...state,
        fetching: true,
    }),
    [initiateCodeWeaversFinance.request]: state => ({
        ...state,
        fetching: false,
    }),
    'AUTH_LOGOUT': () => initialState,
}, initialState);

export const getFetchingCodeweaversFinance = state => state.fetching;
export const isFinanceDetailsVisible = state => state.showFinanceDetailsOnVehicleCard;
